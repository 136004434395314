/* eslint-disable indent */
import React, { useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";
import "../../styles/waitroom.css";
import { AppContext } from "../../App";
import { Dict } from "../WaitingRoom";
import useAlert from "../../hooks/useAlert";
import { Timer } from "../../components/Timer";
import { useQuery } from "@tanstack/react-query";
import { Autocomplete, TextField } from "@mui/material";
import { DummyContainer } from "../../components/Layout";
import { useGraphQLApi } from "../../hooks/useGraphQLApi";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import HealaWordMark from "../../assets/heala-wordmark.svg";
import { cancelConsultation } from "../../graphQL/mutations";
import LoadingLottie from "../../assets/lotties/loading.json";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import usePreventClickBack from "../../hooks/usePreventClickBack";
import { ConsultationDetails, StatusType } from "../../libs/types";
import { WaitingRoomInfoCard } from "../../components/WaitingRoomInfoCard";
import {
  getCurrentConsultationMedium,
  subscribeToWaitRoom,
  updateStart,
  useNotifyConsultation,
} from "../../utils/firestore";
import {
  philipsHMOIDs,
  philipsHMOLogo,
  preventClickBackMsgs,
  status,
} from "../../libs/mock";
import {
  accessDoctorConsultation,
  getConsultation,
  getProviderLogo,
} from "../../graphQL/queries";
import {
  useConsultationContext,
  useConsultationDispatch,
} from "../../contexts/consultationContext";
import {
  ConsultFactory,
  destoryStoredVariables,
  isConsultationTimeReached,
} from "../../utils/funcs";
import { DoctorScheduledWaitingRoom } from "../../features/waiting-room/DoctorWaitingRoom";

const DoctorWaitingRoom = () => {
  usePreventClickBack(preventClickBackMsgs.waitingRoom);
  const navigate = useNavigate();
  const { consultationId } = useParams();
  const { displayAlert, getErrorMsg } = useAlert();
  const [startTimer, setStartTimer] = useState(false);
  const consultationDetails = useConsultationContext();
  const { initVideoAudioConsultation } = useGraphQLApi();
  const { setLoading, setText } = useContext(AppContext);
  const [cancelConsult] = useMutation(cancelConsultation);
  const notifyConsultation = useNotifyConsultation("doctor");
  const updateConsultationDetails = useConsultationDispatch();
  const [providerLogo, setProviderLogo] = useState<string | null>(null);
  const [currentStep, setCurrentStep_] = useState<StatusType | null>(null);
  const [loadingNotifyConsultation, setLoadingNotifyConsultation] =
    useState(false);

  const [now] = useState<number>(
    Number(sessionStorage.getItem("waitingRoomStartTime"))
  );

  const [getAccessToken, { error }] = useLazyQuery(accessDoctorConsultation, {
    variables: { consultationId },
  });

  const [fetchProviderLogo] = useLazyQuery(getProviderLogo, {
    nextFetchPolicy: "no-cache",
  });

  const [fetchConsultation, { error: consultationError, refetch }] =
    useLazyQuery(getConsultation, {
      nextFetchPolicy: "no-cache",
    });

  const { symptoms, patient, doctor, consultationType } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );

  const setCurrentStep = (value: StatusType) => {
    setCurrentStep_(value);
  };

  //GET ACCESS TOKEN AND STORE IT
  const getAccess = async () => {
    const res = await getAccessToken();
    if (res.error) {
      const errMsg = getErrorMsg(res.error) || "Something went wrong!";
      throw Error(`${errMsg}`);
    }
    if (res.data && consultationId) {
      const accessToken =
        res.data?.accessDoctorConsultation?.account?.access_token;
      sessionStorage.setItem("access_token", `${accessToken}`);
    }
  };

  // FETCH CONSULTATION INFO
  const fetchConsultationInfo = async ({ force = false }) => {
    const func = force ? refetch : fetchConsultation;
    const variables = force
      ? { consultationId }
      : { variables: { consultationId } };

    const response = await func(variables);
    if (response && response.data) {
      const info: ConsultationDetails = response.data?.getConsultation;
      updateConsultationDetails(info);
      const { data = {} } = await fetchProviderLogo({
        variables: { providerId: info?.providerId },
      });
      const { getProvider: providerInfo } = data;

      const companyID = info.companyId;
      const isPhilipsHMO = philipsHMOIDs.includes(companyID as string);
      const icon = isPhilipsHMO ? philipsHMOLogo : providerInfo?.icon;

      sessionStorage.setItem("providerLogo", icon);
      sessionStorage.setItem("createdThrough", info.createdThrough);
      setProviderLogo(icon);
      return info;
    }
  };

  // CHECK THE STAGE OF THE CONSULTATION AND ROUTE ACCORDINGLY
  const validateConsultStage = async (
    consultationDetails: ConsultationDetails
  ) => {
    const consultCurrentStatus = consultationDetails?.status;
    setCurrentStep(consultCurrentStatus);

    if (["cancelled", "completed"].includes(consultCurrentStatus)) {
      destoryStoredVariables();
    }

    if (consultCurrentStatus === status.cancelled) {
      throw new Error("Consultation cancelled");
    }
    if (consultCurrentStatus === status.completed) {
      throw new Error("Consultation completed");
    }
  };

  // CALL ON PAGE LOAD TO GET AND STORE ACCESS AND CONSULTATION INFO
  const initApp = async () => {
    try {
      const token = sessionStorage.getItem("access_token");
      const consultation = await (token
        ? fetchConsultationInfo({})
        : getAccess().then(() => fetchConsultationInfo({})));

      consultation &&
        validateConsultStage(consultation).then(() => {
          // INIT VIDEO OR AUDIO CALL
          if (["voice", "video"].includes(consultation?.contactMedium)) {
            initVideoAudioConsultation(`${consultationId}`, false);
          } else {
            sessionStorage.removeItem("agoraInfo");
          }
        });
      return consultation;
    } catch (error) {
      const errMsg = error as Error;
      if (errMsg) displayAlert("error", errMsg?.message);
      setLoading(false);
      console.error(error);
    }
  };

  // CANCEL CONSULTATION ON TIMEOUT
  const endConsultation = async () => {
    try {
      setLoading(true);
      setText("");
      const { data } = await cancelConsult({
        variables: {
          consultationId: consultationId,
          reason:
            "From waiting room: Wait time expired! No doctor joined consultation.",
        },
      });
      if (data) {
        setCurrentStep(status.cancelled);
        destoryStoredVariables();
      }
      setLoading(false);
      setText("");
    } catch (error) {
      displayAlert(
        "error",
        "Something went wrong while trying to end consultation."
      );
      console.error(error);
      setLoading(false);
      setText("");
    }
  };

  // GET STORED FIREBASE CONSULTATION MEDIUM
  const { isPending, data: FSConsultationMedium } = useQuery({
    queryKey: ["consultation-duration"],
    queryFn: async () => getCurrentConsultationMedium(consultationId as string),
  });

  // ROUTE TO CONSULTATION MODE WHEN DOCTOR JOINS
  const onTrigger = (contactMedium: string) => {
    const roomId = `${consultationId}_${doctor._id}_${patient._id}`;

    const medium =
      FSConsultationMedium && FSConsultationMedium !== "NO-MEDIUM"
        ? FSConsultationMedium
        : contactMedium;

    if (medium === "chat") {
      navigate(`/doctor/chat/${roomId}`);
      return;
    }
    if (medium === "voice") {
      navigate(`/doctor/voice/${consultationId}`);
      return;
    }
    if (medium === "video") {
      navigate(`/doctor/video/${consultationId}`);
      return;
    }

    navigate(`/doctor/chat/${roomId}`);
  };

  // GET ACCESS ON FIRST LOAD && GET CONSULTATION INFO && INIT WAITING ROOM
  useEffect(() => {
    initApp()
      .then((consultation) => {
        if (
          consultation?.type === "scheduled" &&
          consultation?.time &&
          !isConsultationTimeReached(new Date(consultation?.time))
        ) {
          return;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [consultationId]);

  // LISTENING TO FIRE STORE
  useEffect(() => {
    if (!consultationId) return;
    if (!startTimer) return;
    const unsubscribe = subscribeToWaitRoom(consultationId, async () => {
      await updateStart(consultationId);
      onTrigger(consultationDetails?.contactMedium as string);
    });

    return () => {
      unsubscribe();
    };
    // remove dependencies
  }, [consultationId, consultationDetails, startTimer]);

  useEffect(() => {
    if (currentStep === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [currentStep]);

  useEffect(() => {
    if (error || consultationError) {
      setLoading(false);
    }
  }, [currentStep]);

  if (error || consultationError) {
    return (
      <DummyContainer>
        <ErrorOutlineIcon
          fontSize="large"
          sx={{ color: "rgb(248 113 113)", marginBottom: "0.5rem" }}
        />
        <p>Something went wrong. Refresh this page.</p>
      </DummyContainer>
    );
  }

  if (isPending)
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <Lottie animationData={LoadingLottie} style={{ width: 15 }} />
      </div>
    );

  return (
    <div className="text-center">
      <div>
        {currentStep && <ConsultationTitle currentStep={currentStep} />}

        {currentStep && canJoin(currentStep) ? (
          <div className="my-10">
            <div>
              <div className=" flex justify-center mb-6">
                {/* PROVIDER LOGO */}
                <img
                  src={providerLogo || HealaWordMark}
                  alt="provider logo"
                  className=" max-w-[150px] max-h-[80px]"
                />
              </div>

              <div className="flex justify-center mx-4">
                {consultationType === "instant" && (
                  <WaitingRoomInfoCard type="doctor">
                    <div className="flex flex-col gap-4">
                      <div className="space-y-5">
                        {/* SYMPTOMS */}
                        {symptoms && symptoms.length > 0 && (
                          <Autocomplete
                            multiple
                            id="symptoms"
                            options={[]}
                            defaultValue={symptoms?.map(
                              (symptom) => symptom?.name
                            )}
                            readOnly
                            renderInput={(params) => (
                              <TextField {...params} label="Symptoms" />
                            )}
                          />
                        )}

                        {/* FIRST NOTICE */}
                        {consultationDetails?.firstNotice && (
                          <div className="waiting_full_input">
                            <TextField
                              id="outlined-basic"
                              label="First notice"
                              variant="outlined"
                              value={consultationDetails?.firstNotice}
                              className="waiting_inputs_full"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </div>
                        )}

                        {/* FIRST NOTICE */}
                        {consultationDetails?.discomfortLevel && (
                          <div className="waiting_full_input">
                            <TextField
                              id="outlined-basic"
                              label="Discomfort level"
                              variant="outlined"
                              value={consultationDetails?.discomfortLevel}
                              className="waiting_inputs_full"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </div>
                        )}

                        {/* DESCRIPTION */}
                        {consultationDetails?.description && (
                          <textarea
                            //label="Description"
                            value={consultationDetails?.description}
                            className="w-full h-24 border border-[#0000003b] rounded-md bg-transparent !p-2"
                            disabled
                          />
                        )}
                      </div>
                      <button
                        disabled={loadingNotifyConsultation}
                        className="bg-primary text-white px-4 py-2 rounded-md"
                        onClick={() => {
                          if (!consultationDetails) return;
                          setLoadingNotifyConsultation(true);

                          notifyConsultation(consultationDetails?._id as string)
                            .then(() => {
                              setStartTimer(true);
                              setLoadingNotifyConsultation(false);
                            })
                            .catch((error) => {
                              setLoadingNotifyConsultation(false);
                              console.error(
                                "Error from notifyConsultation",
                                error
                              );
                            });
                        }}
                      >
                        {loadingNotifyConsultation
                          ? "Loading..."
                          : consultationDetails?.status === "ongoing" &&
                            startTimer
                          ? "Rejoin consultation"
                          : consultationDetails?.status === "ongoing"
                          ? "Rejoin consultation"
                          : startTimer
                          ? "Waiting for patient..."
                          : "Start Consultation"}
                      </button>
                      {startTimer && (
                        <Timer
                          startTimer={startTimer}
                          deadline={now || 180000 + Date.now()}
                          onTimerEnd={() => endConsultation()}
                          bgColor="bg-[#3E5EA921]"
                          color="text-primary"
                          showTimeRunningOutWarning={false}
                          textBeforeTimer="Wait time"
                          threshold={{ mins: 0 }}
                          name="waitingRoomStartTime"
                          persistType="session"
                        />
                      )}
                    </div>
                  </WaitingRoomInfoCard>
                )}

                {consultationType === "scheduled" && (
                  <DoctorScheduledWaitingRoom
                    endConsultation={endConsultation}
                    startTimer={startTimer}
                    setStartTimer={setStartTimer}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export const statusIn = (
  consultationStatus: string | undefined,
  statuses: StatusType[]
) => statuses.includes(consultationStatus as StatusType);

export const canJoin = (v: string | undefined) => {
  return statusIn(v, [status.pending, status.accepted, status.ongoing]);
};

export const ConsultationTitle: React.FC<{
  currentStep: "timeout" | Omit<StatusType, "pending" | "active">;
}> = ({ currentStep }) => {
  if (currentStep === "ongoing") return null;
  const v = Dict[currentStep as string];

  if (!v) return null;

  return (
    <>
      <div className="">
        <div className="info_container mx-auto ">
          <ErrorOutlineIcon
            fontSize="large"
            sx={{ color: "rgb(248 113 113)", marginBottom: "0.5rem" }}
          />
          <h2 className="text-xl text-red-400">{v.heading}</h2>
          {v.content ? <p>{v.content}</p> : null}
        </div>
      </div>
    </>
  );
};

export default DoctorWaitingRoom;
