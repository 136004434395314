import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Countdown, { CountdownApi } from "react-countdown";
import { RenderTimerProps, TimerProps } from "../libs/types";

const TimerText = ({
  text,
  color,
  changeColor,
}: {
  text: string;
  color: string;
  changeColor: boolean;
}) => {
  return (
    <span
      className={`waiitng_time_text text-sm lg:text-base ${
        changeColor ? "text-red-500" : color
      }`}
    >
      {text}
    </span>
  );
};

const RenderTimer = ({ time, threshold, styleParams }: RenderTimerProps) => {
  const { days, hours, minutes, seconds } = time;
  const paddedDays = days < 10 ? `0${days}` : days;
  const hrs = hours < 10 ? `0${hours}` : hours;
  const mins = minutes < 10 ? `0${minutes}` : minutes;
  const secs = seconds < 10 ? `0${seconds}` : seconds;
  const isMinsRunningOut = minutes <= threshold["mins"] && hours < 1;
  const shortTime = `${mins} : ${secs}`;
  const longTime = `${
    Number(paddedDays) < 1 ? "" : paddedDays + "days : "
  }${hrs}h : ${mins}m : ${secs}s`;
  const { enqueueSnackbar } = useSnackbar();
  const {
    showTimeRunningOutWarning,
    bgColor,
    color,
    textBeforeTimer,
    textAfterTimer,
    showFullTime,
  } = styleParams;
  // "bg-[#3E5EA921]" "text-primary"

  useEffect(() => {
    if (isMinsRunningOut && showTimeRunningOutWarning) {
      enqueueSnackbar(
        `You have less than ${threshold["mins"] + 1} minutes left.`,
        { variant: "warning" }
      );
    }
  }, [isMinsRunningOut]);

  return (
    <div>
      {/* {isMinsRunningOut && showTimeRunningOutWarning && (
        <div className="flex justify-center">
          <div className=" bg-yellow-400 font-bold text-center rounded transform transition ease-in-out px-2 lg:px-4 py-1 lg:py-2 my-2">
            <p className="text-white text-xs md:text-sm lg:text-base">{`You have less than ${
              threshold["mins"] + 1
            } minutes left.`}</p>
          </div>
        </div>
      )} */}
      <div
        className={`waiitng_time_div lg:h-[51.38px] rounded-l-full rounded-r-full space-x-2 flex-wrap px-[30px] py-2 ${
          isMinsRunningOut ? "bg-red-200" : bgColor
        }`}
      >
        {textBeforeTimer && (
          <TimerText
            text={textBeforeTimer}
            color={color}
            changeColor={isMinsRunningOut}
          />
        )}
        <TimerText
          text={showFullTime ? longTime : shortTime}
          color={color}
          changeColor={isMinsRunningOut}
        />

        {textAfterTimer && (
          <TimerText
            text={textAfterTimer}
            color={color}
            changeColor={isMinsRunningOut}
          />
        )}
      </div>
    </div>
  );
};

export const Timer = ({
  name,
  deadline,
  startTimer,
  onTimerEnd,
  threshold,
  color,
  bgColor,
  textBeforeTimer,
  textAfterTimer,
  showTimeRunningOutWarning,
  persistType,
  showFullTime,
}: TimerProps) => {
  const [countdownApi, setCountdownApi] = useState<CountdownApi | null>(null);
  const timeType = showFullTime ? showFullTime : false;

  const setRef = (countdown: Countdown) => {
    if (countdown) {
      setCountdownApi(countdown.getApi());
    }
  };

  useEffect(() => {
    if (startTimer && countdownApi) {
      countdownApi.start();
    }
  }, [startTimer, countdownApi]);

  return (
    <Countdown
      ref={setRef}
      autoStart={false}
      onStart={() => {
        if (persistType === "local") localStorage.setItem(name, `${deadline}`);
        if (persistType === "session")
          sessionStorage.setItem(name, `${deadline}`);
      }}
      onComplete={() => onTimerEnd()}
      date={deadline}
      renderer={({ days, hours, minutes, seconds }) => (
        <RenderTimer
          time={{ days, hours, minutes, seconds }}
          threshold={threshold}
          styleParams={{
            showTimeRunningOutWarning,
            color,
            bgColor,
            textBeforeTimer,
            textAfterTimer,
            showFullTime: timeType,
          }}
        />
      )}
    />
  );
};
