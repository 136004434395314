import React, { useEffect, useState } from "react";
import { Timer } from "./Timer";
import { format } from "date-fns";
import { useNotifyConsultation } from "../utils/firestore";
import { WaitingRoomInfoCard } from "./WaitingRoomInfoCard";
import { useConsultationContext } from "../contexts/consultationContext";
import { ConsultFactory, isConsultationTimeReached } from "../utils/funcs";

const ScheduledWaitingRoom = ({
  endConsultation,
  startTimer,
  setStartTimer,
}: {
  endConsultation: () => void;
  startTimer: boolean;
  setStartTimer: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const notifyConsultation = useNotifyConsultation("user");
  const consultationDetails = useConsultationContext();
  const [startScheduledTimer, setStartScheduledTimer] = useState(false);
  const [now] = useState<number>(
    Number(sessionStorage.getItem("waitingRoomStartTime"))
  );
  const { time, _id } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );

  useEffect(() => {
    if (!startTimer && time && !isConsultationTimeReached(new Date(time))) {
      setStartScheduledTimer(true);
    }
  }, [startTimer]);

  const startScheduledConsultationWaitingRoom = () => {
    consultationDetails &&
      notifyConsultation(_id as string)
        .then(() => {
          setStartTimer(true);
          sessionStorage.removeItem("scheduledConsultationReachedTimer");
        })
        .catch((error) => {
          console.error("Error from notifyConsultation", error);
        });
  };

  return (
    <WaitingRoomInfoCard type="user">
      {!startTimer && time && startScheduledTimer && (
        <div className="space-y-2 text-sm text-red-700">
          <p>
            Your consultation will start at{" "}
            <span className="font-semibold underline text-primary">
              {`${format(new Date(time), "p")}`}
            </span>{" "}
            on{" "}
            <span className="font-semibold underline text-primary">
              {`${format(new Date(time), "PPPP")}`}
            </span>
          </p>
          <Timer
            startTimer={startScheduledTimer}
            deadline={new Date(time).getTime()}
            onTimerEnd={() => {
              startScheduledConsultationWaitingRoom();
            }}
            bgColor="bg-[#3E5EA921]"
            color="text-primary"
            showTimeRunningOutWarning={false}
            textAfterTimer=""
            threshold={{ mins: 0 }}
            name="scheduledConsultationReachedTimer"
            persistType="session"
            showFullTime
          />
        </div>
      )}
      {startTimer && (
        <Timer
          startTimer={startTimer}
          deadline={now || 600000 + Date.now()}
          onTimerEnd={() => endConsultation()}
          bgColor="bg-[#3E5EA921]"
          color="text-primary"
          showTimeRunningOutWarning={false}
          textBeforeTimer="Wait time"
          threshold={{ mins: 0 }}
          name="waitingRoomStartTime"
          persistType="session"
        />
      )}
    </WaitingRoomInfoCard>
  );
};

export default ScheduledWaitingRoom;
