import {
  getFirestore,
  doc,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
  updateDoc,
  getDoc,
  getDocFromServer,
} from "firebase/firestore";
import firebaseApp from "./firebase";
import { MessagePayload } from "./firebaseTypes";
import { consultationMedium } from "../libs/types";

const db = getFirestore(firebaseApp);

export const useSaveMessages = () => {
  return async (groupId: string, payload: MessagePayload) => {
    try {
      await addDoc(collection(db, "Messages", groupId, groupId), {
        ...payload,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
};

// CREATE THE CONSULTATION DOCUMENT ON FIRESTORE
export const useNotifyConsultation = (type: "user" | "doctor") => {
  return async (consultationId: string) => {
    try {
      const docRef = doc(db, "Consultation", consultationId);

      // Check if the document exists
      const docSnap = await getDoc(docRef);
      const exists = docSnap.exists();

      if (exists && type === "user") {
        await updateDoc(docRef, {
          "wait-room": true,
        });
        return;
      }

      if (exists && type === "doctor") return;

      // Set the document if it does not exist
      await setDoc(docRef, {
        createdAt: new Date(),
        start: false,
        timer: false,
        "wait-room": type === "user" ? true : false,
        mode: { doctor: null, patient: null },
        endConsultation: false,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
};

export const updateStart = async (documentId: string) => {
  try {
    const docRef = doc(db, "Consultation", documentId);
    await updateDoc(docRef, { start: true });
  } catch (error) {
    console.error("Error checking or updating document:", error);
  }
};

export const useListenToEndConsultation = () => {
  return async (consultationId: string, endConsultation: () => void) => {
    return onSnapshot(doc(db, "Consultation", consultationId), (docs) => {
      if (docs.data()?.endConsultation) {
        endConsultation();
      }
    });
  };
};

export const endConsultationOnFS = async (
  consultationId: string,
  callback: () => void
) => {
  try {
    await updateDoc(doc(db, "Consultation", consultationId), {
      endConsultation: true,
    });
    callback();
  } catch (e) {
    console.error("Error adding document: ", e);
    callback();
  }
};

export const useUpdateTimer = () => {
  return async (consultationId: string) => {
    try {
      await updateDoc(doc(db, "Consultation", consultationId), {
        timer: true,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
};

export const useOnsnapshot = () => {
  return async (
    consultationId: string,
    onTrigger: (contactMedium: string) => void,
    contactMedium: string,
    isDoctorJoined: boolean,
    refreshQuery: () => void
  ) => {
    return onSnapshot(doc(db, "Consultation", consultationId), (docs) => {
      if (docs.data()?.start === true /* && isDoctorJoined */) {
        onTrigger(contactMedium);
      }
      if (docs.data()?.start === true && !isDoctorJoined) {
        //refreshQuery();
      }
    });
  };
};

export const useListenToTimer = () => {
  return async (
    consultationId: string,
    onTrigger: () => void,
    contactMedium: string,
    isDoctorJoined: boolean
  ) => {
    return onSnapshot(doc(db, "Consultation", consultationId), (docs) => {
      if (docs.data()?.timer === true /* && isDoctorJoined */) {
        onTrigger();
      }
    });
  };
};

export const useListenToChat = () => {
  return async (
    groupId: string,
    setMessages: React.Dispatch<
      React.SetStateAction<MessagePayload[] | undefined>
    >
  ) => {
    return onSnapshot(
      collection(db, "Messages", groupId, groupId),
      (querySnapshot) => {
        setMessages(undefined);
        querySnapshot.forEach((doc) => {
          setMessages((prev) => {
            return prev
              ? [doc.data() as MessagePayload, ...prev]
              : [doc.data() as MessagePayload];
          });
        });
      }
    );
  };
};

export const listenToTyping = async (
  roomId: string,
  setTyping: React.Dispatch<React.SetStateAction<boolean>>,
  type: "doctor_typing" | "patient_typing"
) => {
  return onSnapshot(doc(db, "Messages", roomId), (docs) => {
    const isTyping = docs.data()?.[type] || false;
    setTyping(isTyping);
  });
};

export const listenToPatientTypingVar = async (roomId: string) => {
  let isTherePatientTypingVar = false;
  const unSubscribe = await onSnapshot(doc(db, "Messages", roomId), (docs) => {
    const patientTypingVar = docs.data()?.patient_typing;
    const noPatientTypingVar =
      patientTypingVar === undefined || patientTypingVar === null;
    isTherePatientTypingVar = !noPatientTypingVar;
  });

  return { unSubscribe, isTherePatientTypingVar };
};

export const listenToDoctorTypingVar = async (roomId: string) => {
  let isThereDoctorTypingVar = false;
  const unSubscribe = await onSnapshot(doc(db, "Messages", roomId), (docs) => {
    const doctorTypingVar = docs.data()?.doctor_typing;
    const noDoctorTypingVar =
      doctorTypingVar === undefined || doctorTypingVar === null;
    isThereDoctorTypingVar = !noDoctorTypingVar;
  });

  return { unSubscribe, isThereDoctorTypingVar };
};

export const useUpdateIsTyping = () => {
  // UPDATE OR ADD THE PATIENT TYPING VAR TO THE MESSAGES COLLECTION
  const updatePatientIsTyping = async (
    roomId: string,
    isPatientTyping: boolean
  ) => {
    try {
      const docsExists = (await getDoc(doc(db, "Messages", roomId))).exists();
      if (!docsExists)
        await setDoc(doc(db, "Messages", roomId), {
          patient_typing: isPatientTyping,
        });
      await updateDoc(doc(db, "Messages", roomId), {
        patient_typing: isPatientTyping,
      });
    } catch (e) {
      console.error("Error updating patient is typing: ", e);
    }
  };

  // UPDATE OR ADD THE DOCTOR TYPING VAR TO THE MESSAGES COLLECTION
  const updateDoctorIsTyping = async (
    roomId: string,
    isDoctorTyping: boolean
  ) => {
    try {
      const docsExists = (await getDoc(doc(db, "Messages", roomId))).exists();
      if (!docsExists)
        await setDoc(doc(db, "Messages", roomId), {
          doctor_typing: isDoctorTyping,
        });
      await updateDoc(doc(db, "Messages", roomId), {
        doctor_typing: isDoctorTyping,
      });
    } catch (e) {
      console.error("Error updating doctor is typing: ", e);
    }
  };
  return {
    updatePatientIsTyping,
    updateDoctorIsTyping,
  };
};

// CHECK IF THE TYPING VAR EXISTS AND UPDATE IT IF IT DOES NOT
export const checkAndUpdateTypingVar = async (
  roomId: string,
  type: "doctor_typing" | "patient_typing"
) => {
  try {
    const docRef = doc(db, "Messages", roomId);
    const docSnap = await getDoc(docRef);
    const docsExists = docSnap.exists();
    if (!docsExists) await setDoc(docRef, { [type]: false });
  } catch (error) {
    console.error(`Error checking and updating ${type} typing var: `, error);
  }
};

export const subscribeToWaitRoom = (
  consultationId: string,
  onWaitRoomTrue: () => void // Callback function when `wait-room` is true
) => {
  const docRef = doc(db, "Consultation", consultationId);

  // Subscribe to the document
  const unsubscribe = onSnapshot(docRef, async (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.data();

      // Check if `wait-room` is true
      if (data["wait-room"] === true) {
        try {
          // Update `start` to true
          // await updateDoc(docRef, { start: true });

          // Trigger the callback
          onWaitRoomTrue();
        } catch (error) {
          console.error("Error updating start field:", error);
        }
      }
    } else {
      console.error("Document does not exist.");
    }
  });

  // Return the unsubscribe function to stop listening when no longer needed
  return unsubscribe;
};

/**
 * Fetches the value of consultationStartTime property from Firebase Realtime Database.
 * If it doesn't exist yet, it creates it.
 *
 * @param {string} consultationId - The ID of the consultation you want to get consultationStartTime prop from.
 * @param {number} consultationDuration - How long you want the consultation to last. This will be used to create a value for consultationStartTime if it doesn't exist.
 * @returns {Promise<number>} - A promise that resolves to the value of consultationStartTime.
 */

export const getConsultationStartTime = async (
  consultationId: string,
  consultationDuration: number
) => {
  const consultationStartTime = Date.now() + consultationDuration;
  try {
    const docRef = doc(db, "Consultation", consultationId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    if (data && "consultationStartTime" in data) {
      return Number(data?.consultationStartTime) || consultationStartTime;
    }

    // Set the document if it does not exist
    await updateDoc(docRef, {
      consultationStartTime,
    });

    return consultationStartTime;
  } catch (error) {
    console.error(`Error fetching property: ${error}`);
    return consultationStartTime;
  }
};

/**
 * Retrieves the consultation medium for an ongoing consultation.
 *
 * @param {string} consultationId - The unique identifier of the ongoing consultation.
 * @returns {Promise<string | undefined>} - A Promise that resolves to one of the following:
 * - `"NO-MEDIUM"` if no value exists.
 * - A string representing the consultation medium (`"video"`, `"audio"`, or `"voice"`).
 */

export const getCurrentConsultationMedium = async (consultationId: string) => {
  try {
    const docRef = doc(db, "Consultation", consultationId);
    const docSnap = await getDocFromServer(docRef);
    const data = docSnap.data();
    const medium = data?.currentConsultationMedium as consultationMedium;
    return medium ? medium : "NO-MEDIUM";
  } catch (error) {
    console.error("Error from getCurrentConsultationMedium:", error);
    return "NO-MEDIUM";
  }
};

/**
 * Updates the consultation medium for an ongoing consultation.
 *
 * @param {string} consultationId - The unique identifier of the ongoing consultation.
 * @returns {Promise<void>} - A Promise that resolves when the consultation medium is successfully updated.
 */

export const setCurrentConsultationMedium = async (
  consultationId: string,
  medium: consultationMedium
) => {
  try {
    const docRef = doc(db, "Consultation", consultationId);
    await updateDoc(docRef, {
      currentConsultationMedium: medium,
    });
  } catch (error) {
    console.error("Error from setCurrentConsultationMedium:", error);
  }
};
