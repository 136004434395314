import React from "react";
import { TextField } from "@mui/material";
import TimeIcon from "../assets/Time.svg";
import DateIcon from "../assets/Date.svg";
import ConsNote from "../assets/ConsNote.svg";
import AltProfilePics from "./AltProfilePics";
import { ConsultFactory } from "../utils/funcs";
import { useConsultationContext } from "../contexts/consultationContext";

type WaitingRoomInfoCardProps = {
  children: React.ReactNode;
  type: "doctor" | "user";
};

export const WaitingRoomInfoCard = ({
  children,
  type = "user",
}: WaitingRoomInfoCardProps) => {
  const nemProviderId = process.env.REACT_APP_NEM_PROVIDER_ID;
  const consultationDetails = useConsultationContext();
  const doctorInfo = consultationDetails?.doctor;
  const contactMedium = consultationDetails?.contactMedium;
  const lastName = consultationDetails?.patient?.lastName;
  const firstName = consultationDetails?.patient?.firstName;
  const profilePics = consultationDetails?.patient?.image as string;

  const { conTime, conDate, providerId } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );

  return (
    <div className="waiting_info_div">
      <h1 className="waiting_heading">
        {providerId === nemProviderId && (
          <>
            Welcome to NEM virtual clinic <br />
          </>
        )}{" "}
        Waiting Room
      </h1>
      <div>
        <img className="date_time" src={DateIcon} alt="date" />
        <span className="date_time_text">{conDate} </span>
        <span className="date_time_text">|</span>
        <img className="date_time" src={TimeIcon} alt="time" />
        <span className="date_time_text">{`${conTime} (GMT+1)`}</span>
      </div>

      <div className="flex justify-center my-4">
        {profilePics && profilePics !== "undefined" ? (
          <img
            className="my-4 waiting_patient"
            src={profilePics}
            alt={`${firstName} ${lastName}`}
          />
        ) : (
          <AltProfilePics
            firstName={firstName as string}
            lastName={lastName as string}
            bgColor="bg-blue-600"
            textColor="text-white"
          />
        )}
      </div>

      <div className="w-[90%] md:w-full space-y-7 mx-auto">
        <div className="w-full flex space-x-3">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            className="w-full"
            value={firstName}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            className="w-full"
            value={lastName}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div className="waiting_full_input">
          <TextField
            id="outlined-basic"
            label="Communication"
            variant="outlined"
            className="waiting_inputs_full"
            value={contactMedium}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div className="waiting_full_input">
          <TextField
            id="outlined-basic"
            label="Type"
            variant="outlined"
            value={"Instant"}
            className="waiting_inputs_full"
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        {doctorInfo && doctorInfo?.firstName && type === "user" && (
          <div className="waiting_full_input">
            <TextField
              id="outlined-basic"
              label="Consulting with"
              variant="outlined"
              value={`Dr. ${doctorInfo?.firstName} ${
                doctorInfo?.lastName && doctorInfo?.lastName
              }`}
              className="waiting_inputs_full"
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
        )}
      </div>

      <div className=" m-auto mt-5">{children}</div>

      <hr className="hr_tag"></hr>

      <div>
        <img src={ConsNote} alt="ConsNote" />
      </div>
    </div>
  );
};
