import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { consultationMedium } from "../../libs/types";

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
  borderRadius: "10px",
  margin: "0.5rem",
};

export const SwitchRequestModal = ({
  open,
  name,
  mode,
  onAccept,
  onReject,
  ...props
}: {
  open: boolean;
  name: string;
  mode: consultationMedium;
  onAccept: () => Promise<void>;
  onReject: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState<"accepted" | "declined" | null>(null);
  return (
    <div>
      <Modal
        open={open}
        onClose={() => null}
        aria-labelledby={"switch-request-modal-label"}
        aria-describedby={"switch-request-modal-describe"}
        {...props}
      >
        <div className=" h-full flex justify-center items-center">
          <Box sx={style}>
            <Grid
              container
              justifyContent="space-between"
              sx={{ marginBottom: "0.5rem" }}
            >
              <Typography
                id="modal-modal-title"
                component="h2"
                sx={{ textAlign: "center", fontWeight: 500, fontSize: "1.1em" }}
              >
                {`${name} wants to switch to ${mode} consultation.`}
              </Typography>
            </Grid>
            <div className="flex space-x-4 mt-8">
              <button
                disabled={Boolean(loading)}
                onClick={() => {
                  setLoading("declined");
                  onReject()
                    .then(() => setLoading(null))
                    .catch(() => null);
                }}
                className="w-full h-10 text-white bg-red-500 text-sm mx-auto hover:bg-red-700 hover:text-white rounded-md transform transition-all duration-200"
              >
                {loading === "declined" ? "Declining..." : "Decline"}
              </button>
              <button
                disabled={Boolean(loading)}
                onClick={() => {
                  setLoading("accepted");
                  onAccept()
                    .then(() => setLoading(null))
                    .catch(() => null);
                }}
                className="w-full h-10 text-white bg-primary text-sm mx-auto hover:bg-blue-900 hover:text-white rounded-md transform transition-all duration-200"
              >
                {loading === "accepted" ? "Accepting..." : "Accept"}
              </button>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
};
