import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import useAlert from "../../hooks/useAlert";
import { useMutation } from "@apollo/client";
import { preventClickBackMsgs } from "../../libs/mock";
import { RateConsultationStage } from "../../libs/types";
import { ApolloErrorOptions } from "../../libs/interface";
import HealaWordMark from "../../assets/heala-wordmark.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import usePreventClickBack from "../../hooks/usePreventClickBack";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import {
  cancelConsultation,
  updateSatisfaction,
} from "../../graphQL/mutations";
import { destoryStoredVariables } from "../../utils/funcs";

/* 

  <p>
          If the Doctor prescribes any medication, you will receive your pickup
          code via SMS. Processing time for medication takes from 1 to 24hrs. If
          you have any questions, please call us at 020213300150, 02013300151,
          or 02013300152. WhatsApp only: 07032276390
        </p>

*/

const RateConsultationStagesContext = createContext<{
  stage: RateConsultationStage | null;
  setStage: React.Dispatch<React.SetStateAction<RateConsultationStage>> | null;
}>({ stage: null, setStage: null });

let intervalId: NodeJS.Timer | undefined = undefined;
const VerifyConsultationSuccess = () => {
  const navigate = useNavigate();
  const { consultationId } = useParams();
  const [searchParams] = useSearchParams();
  const issues = searchParams.get("issuefrom");
  const { getErrorMsg, displayAlert } = useAlert();
  const [progress, setProgress] = React.useState(100);
  const setStage = useDispatchRateConsultationStages();
  const iconColor = Boolean(issues) === true ? "#FF5F00" : "#3E5EA9";
  const [reportConsultation, { loading }] = useMutation(updateSatisfaction);

  const report = useCallback(async () => {
    try {
      clearInterval(intervalId);
      await reportConsultation({
        variables: {
          consultationId,
          isSatisfied: false,
        },
      });

      setStage && setStage("REPORT_CONSULTATION");
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error as ApolloErrorOptions);
      displayAlert("error", `${errMsg}`);
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 3.33);
    }, 800);
    intervalId = timer;
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress <= 3.33) {
      destoryStoredVariables();
      navigate("/success", { replace: true });
    }
  }, [progress]);

  return (
    <div className="h-screen w-screen flex justify-center bg-[#f6f7fb] items-center px-3">
      <div className="w-[480px] bg-white p-12 rounded-[20px]">
        <div className="flex justify-end w-full">
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" value={progress} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {`${Math.round(progress / 3.33)}`}
              </Typography>
            </Box>
          </Box>
        </div>
        <div className="flex flex-col items-center">
          <div
            className={classNames(
              `w-[100px] h-[100px] flex justify-center items-center rounded-[32px]`,
              {
                "bg-[#FF5F0033]": Boolean(issues),
                "bg-blue-100": Boolean(!issues),
              }
            )}
          >
            <ErrorOutlineIcon sx={{ fontSize: 60, color: iconColor }} />
          </div>
          <div className="text-center mt-4 space-y-2 mb-10">
            {Boolean(issues) && (
              <p className="text-[18px] text-[#757886] leading-5">
                {issues !== "doctor"
                  ? " This consultation ended due to connection issues from the Patient."
                  : " This consultation ended due to connection issues from you."}
              </p>
            )}
          </div>
          <div className="w-full">
            <p className="text-[#192C48] text-xl text-center">
              {`Thank you for using our platform, was this consultation successful?`}
            </p>
            <div className="w-full mt-5 space-x-2 flex">
              <button
                disabled={loading}
                onClick={() => {
                  destoryStoredVariables();
                  navigate("/success", { replace: true });
                }}
                className="w-full h-[46px] bg-[#3E5EA9] text-white rounded-lg"
              >
                Yes
              </button>
              <button
                disabled={loading}
                onClick={() => report()}
                className="w-full h-[46px] border border-[#3E5EA9] text-[#3E5EA9] rounded-lg"
              >
                {loading ? "Loading..." : "No"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportConsultation = () => {
  const navigate = useNavigate();
  const { getErrorMsg } = useAlert();
  const { consultationId } = useParams();
  const [errMsg, setErrMsg] = useState("");
  // const setStage = useDispatchRateConsultationStages();
  // const consultationDetails = useConsultationContext();
  const [reason, setReason] = useState<string | null>(null);
  const textAreaRef = useRef<HTMLInputElement | null>(null);
  const providerLogo = sessionStorage.getItem("providerLogo");
  const [cancelConsult, { loading }] = useMutation(cancelConsultation);

  const report = useCallback(async () => {
    try {
      const cancellationReason = (reason || textAreaRef.current?.value)?.trim();
      if (cancellationReason === "" || typeof cancellationReason !== "string")
        throw Error("No reason provided yet.");

      await cancelConsult({
        variables: {
          consultationId,
          reason: cancellationReason,
        },
      });

      destoryStoredVariables();
      navigate("/success", { replace: true });
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error as ApolloErrorOptions);
      if (!errMsg) {
        const err = error as Error;
        setErrMsg(`${err?.message}`);
      } else {
        setErrMsg(`${errMsg}`);
      }
    }
  }, [reason, textAreaRef]);

  return (
    <div className="h-screen w-screen flex justify-center bg-[#f6f7fb] items-center px-3">
      <div className="max-w-[450px] bg-white rounded-[20px] p-10">
        <div className=" flex justify-center mb-2">
          {/* PROVIDER LOGO */}
          <img
            src={providerLogo || HealaWordMark}
            alt="provider logo"
            className=" max-w-[150px] max-h-[80px]"
          />
        </div>
        <div className="my-5 space-y-3">
          <h1 className="text-2xl text-center">Report this consultation</h1>
          <p className="text-center">
            We are sorry for inconveniences this may have caused. Please tell us
            what went wrong below.
          </p>
        </div>
        <div className="w-full px-4">
          <div className="flex flex-col justify-center mt-6">
            <div className="my-5 space-y-2">
              {[
                {
                  name: "Technical Difficulties",
                  value: "Technical Difficulties",
                },
                { name: "Patient No-Show", value: "Patient No-Show" },
                { name: "Miscommunication", value: "Miscommunication" },
                { name: "Others", value: "" },
              ].map((item, idx) => {
                return (
                  <label
                    key={`${item.name}-${idx}`}
                    className="block space-x-2"
                  >
                    <input
                      type="radio"
                      value={item.value}
                      name={item.value}
                      style={{ accentColor: "#3E5EA9" }}
                      onChange={(e) => setReason(e.target.value)}
                      checked={reason === item.value}
                    />
                    <span>{item.name}</span>
                  </label>
                );
              })}
            </div>
            {reason === "" && (
              <TextField
                inputRef={textAreaRef}
                id="outlined-multiline-static"
                multiline
                rows={4}
                label="Reason"
                variant="outlined"
                className=" w-full"
              />
            )}

            {errMsg && (
              <p
                className={classNames(
                  "bg-red-100 text-red-500 text-sm p-2 rounded-md mt-2 transition-all duration-300 ease-in-out",
                  {
                    invisible: Boolean(!errMsg),
                    visible: Boolean(errMsg),
                  }
                )}
              >
                {errMsg}
              </p>
            )}
          </div>
          <div className="rating_btn_con">
            <button
              disabled={loading}
              className="rating_btn"
              onClick={() => {
                try {
                  setErrMsg("");
                  if (!consultationId) throw Error("No consultation ID found!");
                  report();
                } catch (error) {
                  console.error(error);
                  const err = error as Error;
                  setErrMsg(err?.message);
                }
              }}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConcludeDoctorCommunication = () => {
  usePreventClickBack(preventClickBackMsgs.RateConsultation);
  const [stage, setStage] = useState<RateConsultationStage | null>(null);

  useEffect(() => {
    const sessionStage = sessionStorage.getItem("rateConsultationStage");
    if (sessionStage) {
      setStage(sessionStage as RateConsultationStage);
    } else {
      // sessionStorage.setItem("rateConsultationStage", stage);
      setStage("VERIFY_SUCCESS");
    }
  }, []);

  useEffect(() => {
    if (stage) sessionStorage.setItem("rateConsultationStage", stage);
  }, [stage]);

  return (
    <RateConsultationStagesContext.Provider
      value={{
        stage,
        setStage: setStage as Dispatch<SetStateAction<RateConsultationStage>>,
      }}
    >
      <div className="px-2">{stage && switchStage(stage)}</div>
    </RateConsultationStagesContext.Provider>
  );
};

const useDispatchRateConsultationStages = () => {
  const context = useContext(RateConsultationStagesContext);
  return context?.setStage;
};

const stages: Partial<Record<RateConsultationStage, JSX.Element>> = {
  // RATE_CONSULTATION: <RateConsultationForm />,
  VERIFY_SUCCESS: <VerifyConsultationSuccess />,
  REPORT_CONSULTATION: <ReportConsultation />,
  // RETRY_CONSULTATION: <RetryConsultation />,
};

const switchStage = (stage: RateConsultationStage) => stages[stage];

export default ConcludeDoctorCommunication;
