import React, { useContext, useEffect, useState } from "react";
import { ConsultFactory } from "../../utils/funcs";
import { preventClickBackMsgs } from "../../libs/mock";
import { useGraphQLApi } from "../../hooks/useGraphQLApi";
import usePreventClickBack from "../../hooks/usePreventClickBack";
import { DoctorChatComponent } from "../../components/Chat/DoctorChat";
import { useListenToTimer, useUpdateTimer } from "../../utils/firestore";
import { useConsultationContext } from "../../contexts/consultationContext";
import { AppContext } from "../../App";

export default function DoctorChat() {
  usePreventClickBack(preventClickBackMsgs.chat);

  const listenToFBTimer = useListenToTimer();
  const startFirebaseTimer = useUpdateTimer();
  const { setLoading } = useContext(AppContext);
  const [started, setStarted] = useState(false);
  const consultationInfo = useConsultationContext();
  const [startTimer, setStartTimer] = useState(false);
  const {
    updateJoinedConsultation,
    useGetConsultationInfo,
    updateStartCommunication,
  } = useGraphQLApi();

  const { _id, doctorJoined, doctor, contactMedium } = React.useMemo(
    () => ConsultFactory(consultationInfo),
    [consultationInfo]
  );
  const { fetchConsultation } = useGetConsultationInfo();

  //update consultation
  useEffect(() => {
    if (!doctorJoined && _id) {
      updateJoinedConsultation(
        _id,
        () => null,
        "Doctor failed to update updatedJoinedConsultation",
        "doctorJoined"
      )
        .then(() => updateStartCommunication(_id)) // update communication to ongoing for doctor
        .then(() => fetchConsultation(_id, true))
        .then(() => setStarted(true))
        .catch(() => null);
    } else {
      setStarted(true);
    }
    setLoading(false);
  }, []);

  // start timer
  useEffect(() => {
    if (_id && started) {
      startFirebaseTimer(_id);
    }
  }, [started]);

  // CHECK IF FIREBASE TIMER HAS STARTED THEN START FRONTEND TIMER
  useEffect(() => {
    if (_id) {
      const unsubscribe = listenToFBTimer(
        _id,
        () => setStartTimer(true),
        contactMedium as string,
        typeof doctor._id === "string"
      );

      return () => {
        unsubscribe.then((unsubscribeFn) => unsubscribeFn());
      };
    }
  }, []);

  return (
    <div id="chat_main_container">
      {started && <DoctorChatComponent startTimer={startTimer} />}
    </div>
  );
}
