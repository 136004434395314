export const status = Object.freeze({
  pending: "pending",
  timeout: "timeout",
  ongoing: "ongoing",
  completed: "completed",
  cancelled: "cancelled",
  accepted: "accepted",
  finished: "finished",
  declined: "declined",
});

export const preventClickBackMsgs = Object.freeze({
  waitingRoom: "You can't go back when in the waiting room.",
  chat: "You can't leave a consultation when it has started, click the end chat button to disconnect with this consultation.",
  audioVideo:
    "You can't leave a consultation when it has started, click the end icon to disconnect with this consultation.",
  RateConsultation:
    "Consultation Ended! You can't go back to the consultation room.",
});

export const agoraErrorMsgs = Object.freeze({
  // GENERAL ERRORS
  UNEXPECTED_ERROR: "ERROR: UNEXPECTED ERROR.",
  UNEXPECTED_RESPONSE: "ERROR: UNEXPECTED RESPONSE.",
  INVALID_PARAMS: "Found invalid parameters for this call.",
  NOT_SUPPORTED:
    "This call is not supported by your browser, please switch to a supported browser.",
  INVALID_OPERATION: "INVALID OPERATION",
  OPERATION_ABORTED:
    "This call has been aborted due to poor network conditions or disconnection.",
  WEB_SECURITY_RESTRICT: "Something went wrong, try again!",
  NO_ACTIVE_STATUS: "Something went wrong, try again!",
  //NETWORK ERRORS
  NETWORK_TIMEOUT: "NETWORK TIMEOUT: Check your internet connection.",
  NETWORK_RESPONSE_ERROR: "SERVER RESPONSE ERROR",
  NETWORK_ERROR: "NETWORK ERROR",
  // WEBSOCKET ERRORS
  WS_ABORT: "Websocket disconnected!",
  WS_DISCONNECT: "Websocket disconnected!",
  WS_ERR: "Websocket Error: Browser may not support WebSocket.",
  // DEVICE RELATED ERRORS
  ENUMERATE_DEVICES_FAILED: "Access to microphone or camera failed.",
  DEVICE_NOT_FOUND: "Microphone or Camera not found!",
  //TRACK RELATED ERRORS
  TRACK_IS_DISABLED: "Video or Audio is muted.",
  PERMISSION_DENIED: "Failed to access media device.",
  NOT_READABLE: "Failed to access media device.",
  READ_LOCAL_AUDIO_FILE_ERROR: "Something went wrong, try again!",
  //CLIENT RELATED ERRORS
  UID_CONFLICT: "Duplicate user ID in call.",
  INVALID_UINT_UID_FROM_STRING_UID: "Something went wrong, try again!",
  CAN_NOT_GET_PROXY_SERVER:
    "Failed to join call, please reload this page to try again. If issue persists, end the call and contact support.",
  CAN_NOT_GET_GATEWAY_SERVER:
    "Failed to join call, please reload this page to try again. If issue persists, end the call and contact support.",
  // PUBLISHING / UNPUBLISHING ERRORS
  INVALID_LOCAL_TRACK: "Something went wrong with your microphone or camera.",
  CAN_NOT_PUBLISH_MULTIPLE_VIDEO_TRACKS:
    "You can't connect two camera channels.",
  // SUBSCRIBING / UNSUBSCRIBING ERRORS
  INVALID_REMOTE_USER: "Doctor not in call yet.",
  REMOTE_USER_IS_NOT_PUBLISHED: "Doctor does not have audio or video yet.",
});

export const endConsultationReasons = Object.freeze({
  callEnded: "Patient ended the call.",
  doctorEnded: "Doctor ended call.",

  patientEnded: "Patient ended call.",

  timeout: "Consultation time exhausted.",

  doctorDisconnected: "Connectivity issues from Doctor.",
  patientDisconnected: "Connectivity issues from Patient.",

  patientEnds: "Patient ended call due to connectivity issues.",
  doctorEnds: "Doctor ended call due to connectivity issues.",

  patientEndsDoc: "Patient ended call due to connectivity issues from doctor.",
  doctorEndsPatient:
    "Doctor ended call due to connectivity issues from patient.",
});

export const philipsHMOIDs = [
  "666ea8ac11cbc8e7b983c867",
  "6675e062b0fb4b001253a217",
];

export const philipsHMOLogo =
  "https://dq1z5gvyi71s7.cloudfront.net/heala-file-2024-06-25-13-24-059760.png";
