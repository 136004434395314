import React, { useContext, useRef } from "react";
import useAlert from "./useAlert";
import firebaseApp from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { useGraphQLApi } from "./useGraphQLApi";
import {
  ConsultFactory,
  guessModeForDoctorSwitch,
  guessModeForPatientSwitch,
} from "../utils/funcs";
import {
  getFirestore,
  doc,
  onSnapshot,
  Firestore,
  updateDoc,
} from "firebase/firestore";
import {
  ConsultationDetails,
  consultationMedium,
  consultationMode,
} from "../libs/types";
import { AppContext } from "../App";
import { setCurrentConsultationMedium } from "../utils/firestore";

const db = getFirestore(firebaseApp) as Firestore;

export const useSwitchMode = (
  consultationDetails: ConsultationDetails,
  requestSwitch: (mode: consultationMedium) => void,
  onSwitchMode: () => Promise<unknown>
) => {
  const navigate = useNavigate();
  const { displayAlert } = useAlert();
  const currentMode = useRef<consultationMode>();
  const { setShowAwaitingRes } = useContext(AppContext);
  const { initVideoAudioConsultation } = useGraphQLApi();

  const { doctor, patient, _id } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );

  // CHANGE MODE IN FIREBASE
  const changeModeInFireStore = async (newMode: consultationMode) =>
    await updateDoc(doc(db, "Consultation", _id as string), { mode: newMode });

  // REQUEST SWITCH TO VIDEO VOICE OR CHAT
  const requestModeSwitch = async (
    newMode: consultationMedium,
    type: "doctor" | "patient"
  ) => {
    const mode = currentMode.current?.[type];
    const unchangedMode = type === "doctor" ? "patient" : "doctor";
    await updateDoc(doc(db, "Consultation", _id as string), {
      mode: { [unchangedMode]: mode, [type]: newMode },
    });
  };

  // LISTEN TO FIREBASE FOR CHANGES
  const listenToMode = (type: "doctor" | "patient") => {
    const unsubscribe = onSnapshot(
      doc(db, "Consultation", _id as string),
      (doc) => {
        const newMode = doc.data()?.mode ?? {};
        currentMode.current = newMode;
        interpretMode(newMode, type);
      }
    );
    return unsubscribe;
  };

  // INTERPRET MODE FROM FIREBASE
  const interpretMode = (
    mode: consultationMode,
    type: "doctor" | "patient"
  ) => {
    const action =
      type === "doctor"
        ? guessModeForDoctorSwitch(mode)
        : guessModeForPatientSwitch(mode);
    EventsObject(type)[action]();
  };

  // SWITCH TO CHAT, VOICE OR VIDEO CONSULTATION
  const switchMode = async (
    mode: consultationMedium,
    type: "doctor" | "patient"
  ) => {
    setCurrentConsultationMedium(_id as string, mode);
    const roomId = `${_id}_${doctor._id}_${patient._id}`;
    const chatURL =
      type === "doctor" ? `/doctor/chat/${roomId}` : `/chat/${roomId}`;
    const voiceURL =
      type === "doctor" ? `/doctor/voice/${_id}` : `/voice/${_id}`;
    const videoURL =
      type === "doctor" ? `/doctor/video/${_id}` : `/video/${_id}`;
    setShowAwaitingRes(false);
    changeModeInFireStore({ doctor: null, patient: null });
    const agoraInfo = sessionStorage.getItem("agoraInfo");
    if (mode === "voice") {
      !agoraInfo && (await initVideoAudioConsultation(`${_id}`, true));
      return navigate(voiceURL, { replace: true });
    }
    if (mode === "video") {
      !agoraInfo && (await initVideoAudioConsultation(`${_id}`, true));
      return navigate(videoURL, { replace: true });
    }

    navigate(chatURL, { replace: true });
  };

  // STORE ALL EVENTS FUNCTIONS
  const EventsObject: (
    type: "doctor" | "patient"
  ) => Record<
    ReturnType<
      typeof guessModeForDoctorSwitch | typeof guessModeForPatientSwitch
    >,
    () => void
  > = (type) => {
    return {
      unknown() {
        console.error(">>> unknown mode");
      },

      declined() {
        setShowAwaitingRes(false);
        displayAlert(
          "error",
          `Request to switch mode was declined by the Doctor.`
        );
        changeModeInFireStore({ doctor: null, patient: null });
      },

      requesting() {
        setShowAwaitingRes(true);
      },

      request_for_chat() {
        requestSwitch("chat");
      },

      request_for_video() {
        requestSwitch("video");
      },

      request_for_voice() {
        requestSwitch("voice");
      },

      switch_to_chat() {
        onSwitchMode()
          .then(() => switchMode("chat", type))
          .catch((error) => console.error(error));
      },

      switch_to_video() {
        switchMode("video", type);
      },

      switch_to_voice() {
        switchMode("voice", type);
      },
    };
  };

  return { listenToMode, changeModeInFireStore, requestModeSwitch };
};
