import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Timer } from "../Timer";
import { debounce } from "lodash";
import Lottie from "lottie-react";
import classNames from "classnames";
import { useStyles } from "./styles";
import { Avatar } from "@mui/material";
import { AppContext } from "../../App";
import useAlert from "../../hooks/useAlert";
import { useChat } from "../../hooks/useChat";
import { Timestamp } from "firebase/firestore";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import { Message } from "./components/Message/Message";
import { endConsultationReasons } from "../../libs/mock";
import { useNavigate, useParams } from "react-router-dom";
import { useGraphQLApi } from "../../hooks/useGraphQLApi";
import { useTextCipher } from "../../hooks/useTextCipher";
import { useSwitchMode } from "../../hooks/useSwitchMode";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import { SwitchRequestModal } from "../Modals/SwitchRequest";
import { TextInput } from "./components/TextInput/TextInput";
import SuccessLottie from "../../assets/lotties/loading.json";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { TypingIndicator } from "./components/TypingIndicator";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { EndConsulatation } from "./components/DialogBox/DialogBox";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useConsultationContext } from "../../contexts/consultationContext";
import IntersectionObserverComponent from "../IntersectionObserverComponent";
import {
  ConsultFactory,
  generateReplyToPayload,
  isImageUrl,
  isMsgFromPatient,
  isPdfUrl,
  truncate,
} from "../../utils/funcs";
import {
  /* MessageState, */ MessagePayload,
  ReplyToPayload,
} from "../../utils/firebaseTypes";
import {
  ConsultationDetails,
  consultationMedium,
  endConsultationReasonsType,
} from "../../libs/types";
import {
  checkAndUpdateTypingVar,
  endConsultationOnFS,
  getConsultationStartTime,
  listenToTyping,
  useListenToEndConsultation,
  useUpdateIsTyping,
} from "../../utils/firestore";
import { useQuery } from "@tanstack/react-query";

export const DoctorChatComponent = ({
  startTimer,
}: {
  startTimer: boolean;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const online = useNetworkStatus();
  const { encrypt } = useTextCipher();
  const { displayAlert } = useAlert();
  const rootRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const careone = process.env?.REACT_APP_CAREONE;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { showAwaitingRes } = useContext(AppContext);
  const { roomId } = useParams<{ roomId: string }>();
  const { updateDoctorIsTyping } = useUpdateIsTyping();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const consultationDetails = useConsultationContext();
  const [patientTyping, setPatientTyping] = useState(false);
  const listenToEndConsultation = useListenToEndConsultation();
  const isCareone = consultationDetails?.providerId === careone;
  const { uploadImage, sendEndCommunication } = useGraphQLApi();
  const [msgToReply, setMsgToReply] = useState<MessagePayload | null>(null);
  const [switchMode, setSwitchMode] = useState<consultationMedium>("chat");
  const [consultationId, doctorsId, patientId] = roomId?.split("_") as string[];
  const [loadingRequest, setLoadingRequest] =
    useState<consultationMedium>("chat");
  const { connectToRoom, connectionState, messages, sendMessage } =
    useChat("doctor");
  const { patient } = React.useMemo(
    () => ConsultFactory(consultationDetails || undefined),
    [consultationDetails]
  );

  // GET CONSULTATION DURATION FROM FIREBASE
  const chatDuration = isCareone ? 3600000 : 1800000;
  const { isPending, data } = useQuery({
    queryKey: ["consultation-duration"],
    queryFn: async () => getConsultationStartTime(consultationId, chatDuration),
  });
  const consultationDuration = data || Date.now() + chatDuration;

  // SWITCH FIREBASE HOOK
  const { listenToMode, changeModeInFireStore, requestModeSwitch } =
    useSwitchMode(
      consultationDetails as ConsultationDetails,
      (mode) => setSwitchMode(mode),
      async () => null
    );

  const requestModeChange = useCallback(async (newMode: consultationMedium) => {
    try {
      setLoadingRequest(newMode);
      await requestModeSwitch(newMode, "doctor");
      setLoadingRequest("chat");
    } catch (error) {
      console.error(error);
      const errMsg = error as Error;
      displayAlert("error", errMsg?.message);
      setLoadingRequest("chat");
    }
  }, []);

  const acceptRejectSwitch = useCallback(
    async (response: "accepted" | "rejected") => {
      try {
        await changeModeInFireStore({ patient: switchMode, doctor: response });
        setSwitchMode("chat");
      } catch (error) {
        console.error(error);
        const err = error as Error;
        displayAlert("error", err?.message);
        setSwitchMode("chat");
      }
    },
    [switchMode]
  );

  const handleForwardMessage = useCallback(
    (replyTo: ReplyToPayload | null, value?: string, imgUrl?: string) => {
      try {
        if (!online)
          throw Error("Network error! Please check your internet connection.");
        const content = imgUrl ? imgUrl : value ? value : "";
        // encrypt here
        const encryptedContent = encrypt(content);
        msgToReply && setMsgToReply(null);
        if ((value !== "" || imgUrl) && roomId) {
          sendMessage(roomId as string, {
            idFrom: doctorsId as string,
            timestamp: Timestamp.fromDate(new Date()),
            idTo: patientId as string,
            consultationId: consultationId as string,
            content: encryptedContent,
            status: false,
            replyTo,
          });
        }
      } catch (error) {
        displayAlert("error", "Couldn't send message.");
        console.error("Couldn't send message:", error);
      }
    },
    [online, msgToReply]
  );

  const handleEndChat = (reason: endConsultationReasonsType) => {
    try {
      localStorage.removeItem("consultationStartTime");
      endConsultationOnFS(consultationId, () =>
        sendEndCommunication(consultationId, reason, () => {
          navigate(`/doctor/conclude-communication/${consultationId}`);
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadImage = () => {
    fileInputRef?.current?.click();
  };

  const updateTying = debounce((isTyping: boolean) => {
    updateDoctorIsTyping(roomId as string, isTyping);
  }, 1000);

  const onChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target?.files?.[0];
      if (!file) throw Error("File most be an Image or PDF");
      const isPdfOrImage =
        file?.type?.includes("pdf") || file?.type?.includes("image");
      if (!isPdfOrImage) throw Error("File most be an Image or PDF");

      const response = await uploadImage(file);
      const replyToPayload = msgToReply && generateReplyToPayload(msgToReply);
      if (replyToPayload?.content) {
        replyToPayload.content = encrypt(replyToPayload?.content);
      }
      handleForwardMessage(replyToPayload, response);
    } catch (error) {
      console.error(error);
      const err = error as Error;
      displayAlert("error", err?.message);
    }
  };

  //  CONNECT TO CHAT ROOM
  useEffect(() => {
    const response = connectToRoom(roomId as string);

    return () => {
      response.then((unSubscribe) => unSubscribe && unSubscribe());
    };
  }, []);

  //  CHECK AND UPDATE DOCTOR TYPING VAR IN THE MESSAGES COLLECTION
  useEffect(() => {
    if (connectionState !== "connected") return;
    checkAndUpdateTypingVar(roomId as string, "doctor_typing");
  }, [connectionState]);

  //  LISTEN TO WHEN PATIENT IS TYPING
  useEffect(() => {
    const unSubscribe = listenToTyping(
      roomId as string,
      setPatientTyping,
      "patient_typing"
    );

    return () => {
      unSubscribe.then((unsubscribeFn) => unsubscribeFn());
    };
  }, []);

  // LISTEN TO CHANGES IN CONSULTATION MODE
  useEffect(() => {
    const unsubscribeFn = listenToMode("doctor");
    return () => {
      unsubscribeFn();
    };
  }, []);

  // LISTEN FOR WHEN DOCTOR ENDS CALL AND LEAVE THE CALL
  useEffect(() => {
    const unsubscribe = listenToEndConsultation(`${consultationId}`, () =>
      sendEndCommunication(
        consultationId,
        endConsultationReasons.callEnded,
        () => {
          navigate(`/doctor/conclude-communication/${consultationId}`);
        }
      )
    );

    return () => {
      unsubscribe.then((unsubscribeFN) => unsubscribeFN());
    };
  }, []);

  useEffect(() => {
    const height = rootRef.current?.scrollHeight;
    rootRef.current?.scrollTo({
      behavior: "smooth",
      top: Number(height) + 50,
    });
  }, [messages]);

  return (
    <div
      className={`w-full h-screen md:w-[530px] flex flex-col space-y-2 mx-auto lg:p-4`}
    >
      {/*  HEADER  */}
      <div
        className={`block md:flex justify-between items-center bg-white rounded-2xl space-y-4 md:space-y-0 mt-4 px-4 py-4 lg:px-6 lg:py-7 mx-2`}
      >
        <div
          className={`flex items-center space-x-4 justify-center md:justify-start`}
        >
          <Avatar alt={"displayName"} src={patient?.image as string}></Avatar>

          <div>
            <h1 className="m-0 p-0 ">
              {patient?.firstName &&
                `${patient?.firstName} ${patient?.lastName}`}
            </h1>
            {patientTyping && !visible && (
              <p className=" text-[10px] font-semibold text-blue-500">{`${patient?.firstName} is typing...`}</p>
            )}
          </div>
        </div>

        <div className="flex justify-center items-center md:justify-start space-x-4 md:space-x-2">
          <button
            disabled={loadingRequest !== "chat"}
            onClick={() => requestModeChange("video")}
            className=" text-[#3E5EA9] text-sm rounded-md px-2 py-1 hover:bg-[#3E5EA9] hover:text-white transform transition-all duration-200"
          >
            {loadingRequest === "video" ? (
              "Loading..."
            ) : (
              <VideocamOutlinedIcon />
            )}
          </button>
          <button
            disabled={loadingRequest !== "chat"}
            onClick={() => requestModeChange("voice")}
            className=" text-[#3E5EA9] text-sm  rounded-md px-2 py-1 hover:bg-[#3E5EA9] hover:text-white transform transition-all duration-200"
          >
            {loadingRequest === "voice" ? "Loading..." : <CallOutlinedIcon />}
          </button>
          <EndConsulatation
            handleEnd={() => handleEndChat(endConsultationReasons.doctorEnded)}
            content={"Are you sure you want to end the chat"}
          />
        </div>
      </div>

      {isPending ? (
        <div className="flex justify-center space-x-2">
          <p className="text-sm font-medium">LOADING</p>
          <Lottie animationData={SuccessLottie} style={{ width: 15 }} />
        </div>
      ) : (
        <>
          {/*  CHAT BODY */}
          <div className=" bg-white flex flex-col flex-1 flex-grow overflow-y-scroll relative rounded-2xl mx-2">
            <div ref={rootRef} id="style-1" className={classes.messagesBody}>
              <div className="sticky top-0 z-30">
                <div
                  style={{ width: "100%" }}
                  className=" bg-white pb-4 border-b relative z-30"
                >
                  <p className={classes.messageTime}>
                    {new Date().toDateString()}
                  </p>
                  <Timer
                    startTimer={startTimer}
                    deadline={consultationDuration}
                    onTimerEnd={() =>
                      handleEndChat(endConsultationReasons.timeout)
                    }
                    bgColor="bg-[#3E5EA921]"
                    showTimeRunningOutWarning={true}
                    color="text-primary"
                    textAfterTimer="minutes remaining"
                    threshold={{ mins: 4 }}
                    name="consultationStartTime"
                    persistType="local"
                  />

                  {online && showAwaitingRes && (
                    <div className="flex justify-center mt-2">
                      <div className=" bg-[#E4F2FF] rounded-lg p-4 text-center">
                        <p className=" font-semibold text-sm text-[#1A7ABF]">{`Waiting for ${patient?.firstName} ${patient?.lastName} to accept ...`}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    " transition duration-300 ease-in-out relative z-10",
                    {
                      "-translate-y-14": online,
                      "translate-y-2": !online,
                    }
                  )}
                >
                  <p className="text-sm text-center font-medium text-red-500 bg-red-100 px-3 py-1">
                    You are offline!
                  </p>
                </div>
              </div>

              {/*  SHOW MESSAGES */}
              {connectionState === "connecting" && (
                <div className="flex justify-center space-x-2">
                  <p className="text-sm font-medium">LOADING</p>
                  <Lottie animationData={SuccessLottie} style={{ width: 15 }} />
                </div>
              )}
              {connectionState === "connected" && (
                <>
                  <div className=" space-y-10 my-5 relative">
                    {messages.map((message, id) => {
                      const fromPatient = isMsgFromPatient(message, patientId);
                      const align = !fromPatient ? "right" : "left";
                      const status = message.status ? "delivered" : "sent";

                      return (
                        <Message
                          key={`${id}-${message._id}`}
                          align={align}
                          status={status}
                          message={message}
                          showStatus={message.idFrom === doctorsId}
                          type="doctor"
                          onClickReply={() => {
                            setMsgToReply(message);
                            inputRef.current?.focus();
                          }}
                        />
                      );
                    })}
                  </div>
                  {/*  SHOW TYPING INDICATOR */}
                  <div className="h-[52px]">
                    {patientTyping && (
                      <IntersectionObserverComponent
                        root={rootRef.current}
                        onIntersect={(entry: IntersectionObserverEntry) => {
                          if (entry?.intersectionRatio > 0.5) {
                            setVisible(true);
                          } else {
                            setVisible(false);
                          }
                        }}
                      >
                        <div className="flex my-4">
                          <TypingIndicator />
                        </div>
                      </IntersectionObserverComponent>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          {/*  TYPE IN MESSAGE */}
          <div
            className={`flex bg-white flex-col justify-between px-4 py-4 lg:px-4 lg:py-7 rounded-2xl sticky bottom-0 md:bottom-3 left-0 border md:border-0 mx-2`}
          >
            {msgToReply && (
              <div className="flex justify-between items-center border-l-4 rounded-md bg-gray-100 border-primary px-4 py-2 m-3">
                <div className="w-[90%]">
                  <p className=" text-primary text-xs font-medium mb-1">
                    {msgToReply.idFrom === patientId
                      ? "You"
                      : `${patient?.firstName}`}
                  </p>
                  <div>
                    {isPdfUrl(msgToReply.content) ? (
                      <div>
                        <PictureAsPdfIcon />
                        <p className=" text-[10px]">DOCUMENT</p>
                      </div>
                    ) : isImageUrl(msgToReply.content) ? (
                      <div>
                        <ImageIcon />
                        <p className=" text-[10px]">IMAGE</p>
                      </div>
                    ) : (
                      <p className="w-[90%] text-sm text-gray-500 break-words">
                        {truncate(msgToReply.content, 100)}
                      </p>
                    )}
                  </div>
                </div>
                <button onClick={() => setMsgToReply(null)}>
                  <CloseIcon className=" text-red-500" />
                </button>
              </div>
            )}
            <TextInput
              ref={inputRef}
              onClick={async (value) => {
                try {
                  const replyToPayload =
                    msgToReply && generateReplyToPayload(msgToReply);
                  updateTying.cancel();
                  updateDoctorIsTyping(roomId as string, false);
                  if (replyToPayload?.content) {
                    replyToPayload.content = encrypt(replyToPayload?.content);
                  }
                  handleForwardMessage(replyToPayload, value);
                  return "success";
                } catch (error) {
                  return "error";
                }
              }}
              uploadImage={handleUploadImage}
              setTyping={(isTyping) => updateTying(isTyping)}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={onChangeImage}
            />
          </div>
        </>
      )}

      {/* REQUEST MODAL */}
      <SwitchRequestModal
        mode={switchMode}
        open={Boolean(switchMode !== "chat")}
        name={`${patient?.firstName} ${patient?.lastName}`}
        onAccept={() => acceptRejectSwitch("accepted")}
        onReject={() => acceptRejectSwitch("rejected")}
      />
    </div>
  );
};
