import { gql } from "@apollo/client";

export const cancelConsultation = gql`
  mutation cancelConsultation($consultationId: String!, $reason: String!) {
    cancelConsultation(data: { id: $consultationId, reason: $reason }) {
      consultation {
        _id
        providerId
        patient {
          _id
          firstName
          lastName
          height
          weight
          bloodGroup
          genotype
          gender
          phoneNumber
          email
          provider
        }
        doctor {
          _id
          firstName
          lastName
          balance
          gender
          phoneNumber
        }
      }
    }
  }
`;

export const initRtc = gql`
  mutation initRtc($consultationId: String!) {
    initRtc(data: { consultationId: $consultationId }) {
      uid
      rtcToken
    }
  }
`;

export const END_COMMUNICATION = gql`
  mutation endCommunication($consultationId: ID!) {
    endCommunication(data: { id: $consultationId }) {
      consultation {
        _id
        patient {
          _id
          firstName
          lastName
          height
          weight
          bloodGroup
          genotype
          gender
          phoneNumber
          email
          provider
        }
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        consultationDuration
        trackingId
        doctor {
          _id
          firstName
          lastName
          balance
          gender
          phoneNumber
        }
        providerId
        createdAt
        updatedAt
      }
    }
  }
`;

export const RATE_CONSULTATION = gql`
  mutation rateConsultation(
    $consultationId: String!
    $review: String
    $score: Float!
    $comment: String!
  ) {
    rateConsultation(
      data: {
        consultationId: $consultationId
        review: $review
        score: $score
        comment: $comment
      }
    ) {
      consultation {
        _id
        patient {
          _id
          firstName
          lastName
        }
        consultationOwner
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        providerId
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const createConsultation = gql`
  mutation createConsultation(
    $consultationOwner: String!
    $patient: String!
    $symptoms: [Symptom!]!
    $discomfortLevel: String!
    $description: String
    $firstNotice: String!
    $type: String
    $status: String
    $providerId: String
    $contactMedium: String
    $doctor: String
  ) {
    createConsultation(
      data: {
        consultationOwner: $consultationOwner
        patient: $patient
        symptoms: $symptoms
        discomfortLevel: $discomfortLevel
        description: $description
        firstNotice: $firstNotice
        type: $type
        status: $status
        providerId: $providerId
        contactMedium: $contactMedium
        doctor: $doctor
      }
    ) {
      consultation {
        _id
      }
    }
  }
`;

export const updatedJoinedConsultation = gql`
  mutation updateJoinedConsultation(
    $consultationId: String!
    $patientJoined: Boolean
    $doctorJoined: Boolean
  ) {
    updateJoinedConsultation(
      data: {
        id: $consultationId
        patientJoined: $patientJoined
        doctorJoined: $doctorJoined
      }
    ) {
      consultation {
        _id
        status
        consultationOwner
        doctor {
          _id
          firstName
          lastName
          balance
          gender
          phoneNumber
        }
        patient {
          _id
          firstName
          lastName
          height
          weight
          bloodGroup
          genotype
          gender
          phoneNumber
          email
          provider
        }
        providerId
        declineReason
        consultationDuration
        contactMedium
        createdThrough
        type
        time
        createdAt
        updatedAt
        referralId
        firstNotice
        description
        discomfortLevel
        symptoms {
          name
        }
      }
    }
  }
`;

export const rebroadcastNotification = gql`
  mutation rebroadcastConsultationNotification($consultationId: ID!) {
    rebroadcastConsultationNotification(data: { id: $consultationId }) {
      consultation {
        _id
        patient {
          _id
          firstName
          lastName
          height
          weight
          bloodGroup
          genotype
          gender
          phoneNumber
          email
          provider
        }
        consultationOwner
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        trackingId
        notificationSchedules {
          _id
          time
          notified
          consultationId
        }
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        doctor {
          _id
          firstName
          lastName
          balance
          gender
          phoneNumber
        }
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        providerId
        createdAt
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const updateSatisfaction = gql`
  mutation updateSatisfaction(
    $consultationId: String!
    $isSatisfied: Boolean
    $reason: String
  ) {
    updateSatisfaction(
      data: {
        id: $consultationId
        patientSatisfied: $isSatisfied
        patientSatisfactionReason: $reason
      }
    ) {
      consultation {
        _id
        patient {
          _id
          firstName
          lastName
          height
          weight
          bloodGroup
          genotype
          gender
          phoneNumber
          email
          provider
        }
        consultationOwner
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        createdAt
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        trackingId
        patientJoined
        doctorJoined
        notificationSchedules {
          _id
          time
          notified
          consultationId
        }
        status
        updatedAt
        contactMedium
        providerId
        patientSatisfied
        doctorSatisfied
      }
    }
  }
`;

// isDisputed: true
export const createDispute = gql`
  mutation createDispute($consultationId: ID!, $disputeReason: String!) {
    createDispute(
      data: { id: $consultationId, disputeReason: $disputeReason }
    ) {
      consultation {
        _id
        patient {
          _id
          firstName
          lastName
          height
          weight
          bloodGroup
          genotype
          gender
          phoneNumber
          email
          provider
        }
        consultationOwner
        time
        type
        appointmentAcceptedAt
        patientEndCommunicationReason
        appointmentStartedAt
        consultationDuration
        trackingId
        notificationSchedules {
          _id
          time
          notified
          consultationId
        }
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        doctor {
          _id
          firstName
          lastName
          balance
          gender
          phoneNumber
        }
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        providerId
        createdAt
        updatedAt
      }
    }
  }
`;

export const startCommunication = gql`
  mutation startCommunication($consultationId: ID!) {
    startCommunication(data: { id: $consultationId }) {
      consultation {
        _id
      }
    }
  }
`;
