import { ConsultFactory } from "../utils/funcs";
import React, { useContext, useEffect, useState } from "react";
import { preventClickBackMsgs } from "../libs/mock";
import { useGraphQLApi } from "../hooks/useGraphQLApi";
import { ChatComponent } from "../components/Chat/Chat";
import usePreventClickBack from "../hooks/usePreventClickBack";
import { useListenToTimer, useUpdateTimer } from "../utils/firestore";
import { useConsultationContext } from "../contexts/consultationContext";
import { AppContext } from "../App";

export const Chat = () => {
  usePreventClickBack(preventClickBackMsgs.chat);

  const listenToFBTimer = useListenToTimer();
  const startFirebaseTimer = useUpdateTimer();
  const { setLoading } = useContext(AppContext);
  const [started, setStarted] = useState(false);
  const consultationInfo = useConsultationContext();
  const [startTimer, setStartTimer] = useState(false);
  const { updateJoinedConsultation, useGetConsultationInfo } = useGraphQLApi();
  const { _id, patientJoined, doctor, contactMedium } = React.useMemo(
    () => ConsultFactory(consultationInfo),
    [consultationInfo]
  );
  const { fetchConsultation } = useGetConsultationInfo();

  //update consultation
  useEffect(() => {
    if (!patientJoined && _id) {
      updateJoinedConsultation(
        _id,
        () => null,
        "Patient failed to update updatedJoinedConsultation",
        "patientJoined"
      )
        .then(() => fetchConsultation(_id, true))
        .then(() => setStarted(true))
        .catch(() => null);
    } else {
      setStarted(true);
    }
    setLoading(false);
  }, []);

  // start timer
  useEffect(() => {
    if (_id && started) {
      startFirebaseTimer(_id);
    }
  }, [started]);

  // CHECK IF FIREBASE TIMER HAS STARTED THEN START FRONTEND TIMER
  useEffect(() => {
    if (_id) {
      const unsubscribe = listenToFBTimer(
        _id,
        () => setStartTimer(true),
        contactMedium as string,
        typeof doctor._id === "string"
      );

      return () => {
        unsubscribe.then((unsubscribeFn) => unsubscribeFn());
      };
    }
  }, []);

  return (
    <div id="chat_main_container">
      {started && <ChatComponent startTimer={startTimer} />}
    </div>
  );
};
