/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Timer } from "../../components/Timer";
import { Autocomplete, TextField } from "@mui/material";
import { useNotifyConsultation } from "../../utils/firestore";
import { WaitingRoomInfoCard } from "../../components/WaitingRoomInfoCard";
import { ConsultFactory, isConsultationTimeReached } from "../../utils/funcs";
import { useConsultationContext } from "../../contexts/consultationContext";

// const ConsultationInformation = ({
//   endConsultation,
//   startTimer,
//   setStartTimer,
//   now,
// }: {
//   endConsultation: () => void;
//   startTimer: boolean;
//   setStartTimer: React.Dispatch<React.SetStateAction<boolean>>;
//   now: number;
// }) => {
//   const consultationDetails = useConsultationContext();
//     const notifyConsultation = useNotifyConsultation("doctor");
//   const [loadingNotifyConsultation, setLoadingNotifyConsultation] =
//     useState(false);

//   const { symptoms, _id } = React.useMemo(
//     () => ConsultFactory(consultationDetails),
//     [consultationDetails]
//   );

//     const startDoctorWaitingRoom = () => {
//       if (!consultationDetails) return;
//       setLoadingNotifyConsultation(true);

//       notifyConsultation(_id as string)
//         .then(() => {
//           setStartTimer(true);
//           setLoadingNotifyConsultation(false);
//         })
//         .catch((error) => {
//           setLoadingNotifyConsultation(false);
//           console.error("Error from notifyConsultation", error);
//         });
//     };

//   return (
//     <WaitingRoomInfoCard type="doctor">
//       <div className="flex flex-col gap-4">
//         <div className="space-y-5">
//           {/* SYMPTOMS */}
//           {symptoms && symptoms.length > 0 && (
//             <Autocomplete
//               multiple
//               id="symptoms"
//               options={[]}
//               defaultValue={symptoms?.map((symptom) => symptom?.name)}
//               readOnly
//               renderInput={(params) => (
//                 <TextField {...params} label="Symptoms" />
//               )}
//             />
//           )}

//           {/* FIRST NOTICE */}
//           {consultationDetails?.firstNotice && (
//             <div className="waiting_full_input">
//               <TextField
//                 id="outlined-basic"
//                 label="First notice"
//                 variant="outlined"
//                 value={consultationDetails?.firstNotice}
//                 className="waiting_inputs_full"
//                 InputProps={{
//                   readOnly: true,
//                 }}
//               />
//             </div>
//           )}

//           {/* FIRST NOTICE */}
//           {consultationDetails?.discomfortLevel && (
//             <div className="waiting_full_input">
//               <TextField
//                 id="outlined-basic"
//                 label="Discomfort level"
//                 variant="outlined"
//                 value={consultationDetails?.discomfortLevel}
//                 className="waiting_inputs_full"
//                 InputProps={{
//                   readOnly: true,
//                 }}
//               />
//             </div>
//           )}

//           {/* DESCRIPTION */}
//           {consultationDetails?.description && (
//             <textarea
//               //label="Description"
//               value={consultationDetails?.description}
//               className="w-full h-24 border border-[#0000003b] rounded-md bg-transparent"
//               disabled
//             />
//           )}
//         </div>
//         <button
//           disabled={loadingNotifyConsultation}
//           className="bg-primary text-white px-4 py-2 rounded-md"
//           onClick={() => startDoctorWaitingRoom()}
//         >
//           {loadingNotifyConsultation
//             ? "Loading..."
//             : startTimer
//             ? "Waiting for patient..."
//             : "Start Consultation"}
//         </button>
//         {startTimer && (
//           <Timer
//             startTimer={startTimer}
//             deadline={now || 180000 + Date.now()}
//             onTimerEnd={() =>
//               process.env.NODE_ENV === "development"
//                 ? console.log(">>> endConsultation")
//                 : endConsultation()
//             }
//             bgColor="bg-[#3E5EA921]"
//             color="text-primary"
//             showTimeRunningOutWarning={false}
//             textBeforeTimer="Wait time"
//             threshold={{ mins: 0 }}
//             name="waitingRoomStartTime"
//             persistType="session"
//           />
//         )}
//       </div>
//     </WaitingRoomInfoCard>
//   );
// };

export const DoctorScheduledWaitingRoom = ({
  endConsultation,
  startTimer,
  setStartTimer,
}: {
  endConsultation: () => void;
  startTimer: boolean;
  setStartTimer: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const notifyConsultation = useNotifyConsultation("doctor");
  const consultationDetails = useConsultationContext();
  const [startScheduledTimer, setStartScheduledTimer] = useState(false);
  const [loadingNotifyConsultation, setLoadingNotifyConsultation] =
    useState(false);

  const [now] = useState<number>(
    Number(sessionStorage.getItem("waitingRoomStartTime"))
  );
  const { time, _id, symptoms } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );

  const [scheduledTimeReached, setScheduledTimeReached] = useState(
    isConsultationTimeReached(new Date(`${time}`))
  );

  useEffect(() => {
    if (!startTimer && time && !isConsultationTimeReached(new Date(time))) {
      setStartScheduledTimer(true);
    }
  }, [startTimer]);

  const startScheduledConsultationWaitingRoom = () => {
    if (!consultationDetails) return;
    setLoadingNotifyConsultation(true);

    notifyConsultation(_id as string)
      .then(() => {
        setStartTimer(true);
        setLoadingNotifyConsultation(false);
      })
      .catch((error) => {
        setLoadingNotifyConsultation(false);
        console.error("Error from notifyConsultation", error);
      });
  };

  return (
    <WaitingRoomInfoCard type="doctor">
      <div className="flex flex-col gap-4">
        <div className="space-y-5">
          {/* SYMPTOMS */}
          {symptoms && symptoms.length > 0 && (
            <Autocomplete
              multiple
              id="symptoms"
              options={[]}
              defaultValue={symptoms?.map((symptom) => symptom?.name)}
              readOnly
              renderInput={(params) => (
                <TextField {...params} label="Symptoms" />
              )}
            />
          )}

          {/* FIRST NOTICE */}
          {consultationDetails?.firstNotice && (
            <div className="waiting_full_input">
              <TextField
                id="outlined-basic"
                label="First notice"
                variant="outlined"
                value={consultationDetails?.firstNotice}
                className="waiting_inputs_full"
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
          )}

          {/* FIRST NOTICE */}
          {consultationDetails?.discomfortLevel && (
            <div className="waiting_full_input">
              <TextField
                id="outlined-basic"
                label="Discomfort level"
                variant="outlined"
                value={consultationDetails?.discomfortLevel}
                className="waiting_inputs_full"
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
          )}

          {/* DESCRIPTION */}
          {consultationDetails?.description && (
            <textarea
              //label="Description"
              value={consultationDetails?.description}
              className="w-full h-24 border border-[#0000003b] rounded-md bg-transparent !p-2"
              disabled
            />
          )}
        </div>

        {!startTimer && time && startScheduledTimer && (
          <div className="space-y-2 text-sm text-red-700">
            <p>
              Your consultation will start at{" "}
              <span className="font-semibold underline text-primary">
                {`${format(new Date(time), "p")}`}
              </span>{" "}
              on{" "}
              <span className="font-semibold underline text-primary">
                {`${format(new Date(time), "PPPP")}`}
              </span>
            </p>
            <Timer
              startTimer={startScheduledTimer}
              deadline={new Date(time).getTime()}
              onTimerEnd={() => {
                setScheduledTimeReached(true);
              }}
              bgColor="bg-[#3E5EA921]"
              color="text-primary"
              showTimeRunningOutWarning={false}
              textAfterTimer=""
              threshold={{ mins: 0 }}
              name="scheduledConsultationReachedTimer"
              persistType="session"
              showFullTime
            />
          </div>
        )}

        {!startTimer && scheduledTimeReached && (
          <button
            disabled={loadingNotifyConsultation}
            className="bg-primary text-white px-4 py-2 rounded-md"
            onClick={() => startScheduledConsultationWaitingRoom()}
          >
            {loadingNotifyConsultation
              ? "Loading..."
              : startTimer
              ? "Waiting for patient..."
              : "Start Consultation"}
          </button>
        )}
        {startTimer && (
          <Timer
            startTimer={startTimer}
            deadline={now || 600000 + Date.now()}
            onTimerEnd={() => endConsultation()}
            bgColor="bg-[#3E5EA921]"
            color="text-primary"
            showTimeRunningOutWarning={false}
            textBeforeTimer="Wait time"
            threshold={{ mins: 0 }}
            name="waitingRoomStartTime"
            persistType="session"
          />
        )}
      </div>
    </WaitingRoomInfoCard>
  );
};
