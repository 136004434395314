import React, { useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";
import { AppContext } from "../App";
import Call from "../components/Call/Call";
import { Timer } from "../components/Timer";
import { ConsultFactory } from "../utils/funcs";
import { useQuery } from "@tanstack/react-query";
import { preventClickBackMsgs } from "../libs/mock";
import { useGraphQLApi } from "../hooks/useGraphQLApi";
import { useLocation, useParams } from "react-router-dom";
import SuccessLottie from "../assets/lotties/loading.json";
import usePreventClickBack from "../hooks/usePreventClickBack";
import { useConsultationContext } from "../contexts/consultationContext";
import {
  getConsultationStartTime,
  useListenToTimer,
  useUpdateTimer,
} from "../utils/firestore";
import { AgoraInfo } from "../libs/types";

const VideoAudioApp = () => {
  usePreventClickBack(preventClickBackMsgs.audioVideo);
  const { pathname } = useLocation();
  const appId = React.useRef(process.env.REACT_APP_AGORA_APP_ID || "").current;
  const { consultationId } = useParams();
  const listenToFBTimer = useListenToTimer();
  const startFirebaseTimer = useUpdateTimer();
  const contactMedium = pathname.split("/")[1];
  const careone = process.env?.REACT_APP_CAREONE;
  const { showAwaitingRes } = useContext(AppContext);
  const [joinedCall, setJoinedCall] = useState(false);
  const [timerEnded, setTimerEnded] = useState(false);
  const consultationDetails = useConsultationContext();
  const isCareone = consultationDetails?.providerId === careone;
  const [startTimer, setStartTimer] = useState<boolean>(false);
  const { updateJoinedConsultation, initVideoAudioConsultation } =
    useGraphQLApi();

  const { isPending: loadingAgoraInfo, data: agoraInfo } = useQuery({
    queryKey: ["agoraInfo"],
    queryFn: async () => {
      const storedInfo = JSON.parse(
        sessionStorage.getItem("agoraInfo") as string
      );
      if (storedInfo) return storedInfo as AgoraInfo;
      const response = await initVideoAudioConsultation(
        `${consultationId}`,
        true
      );
      return response;
    },
  });

  // GET CONSULTATION DURATION FROM FIREBASE
  const chatDuration = isCareone ? 3600000 : 1800000;
  const { isPending, data } = useQuery({
    queryKey: ["consultation-duration"],
    queryFn: async () =>
      getConsultationStartTime(consultationId as string, chatDuration),
  });
  const consultationDuration = data || Date.now() + chatDuration;

  const { doctor } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );
  const { firstName, lastName } = doctor;

  const getCallType = (string: string) =>
    string === "video" ? "video" : string === "voice" ? "audio" : "video";

  useEffect(() => {
    if (consultationId && joinedCall) startFirebaseTimer(`${consultationId}`);
  }, [joinedCall]);

  useEffect(() => {
    if (consultationId && consultationDetails) {
      const unsubscribe = listenToFBTimer(
        consultationId,
        () => setStartTimer(true),
        consultationDetails?.contactMedium as string,
        typeof consultationDetails?.doctor?._id === "string"
      );

      return () => {
        unsubscribe.then((unsubscribeFn) => unsubscribeFn());
      };
    }
  }, []);

  useEffect(() => {
    if (!consultationId) return;
    updateJoinedConsultation(
      `${consultationId}`,
      () => setJoinedCall(true),
      "Patient failed to update updatedJoinedConsultation",
      "patientJoined"
    );
  }, []);

  return (
    <>
      {isPending || loadingAgoraInfo ? (
        <div className="flex justify-center space-x-2">
          <p className="text-sm font-medium">LOADING</p>
          <Lottie animationData={SuccessLottie} style={{ width: 15 }} />
        </div>
      ) : (
        joinedCall && (
          <div className=" w-full h-screen flex flex-col relative sm:bg-transparent lg:bg-[#313131]">
            <div className="w-full absolute lg:static z-50">
              <Timer
                startTimer={startTimer}
                deadline={consultationDuration}
                onTimerEnd={() => setTimerEnded(true)}
                bgColor="bg-white"
                showTimeRunningOutWarning={true}
                color="text-primary"
                textAfterTimer="minutes remaining"
                threshold={{ mins: 4 }}
                name="consultationStartTime"
                persistType="local"
              />
              {showAwaitingRes && (
                <div className="flex justify-center mt-2">
                  <div className=" bg-[#E4F2FF] rounded-lg p-4 text-center">
                    <p className=" font-semibold text-sm text-[#1A7ABF]">{`Waiting for Dr. ${firstName} ${lastName} to accept ...`}</p>
                  </div>
                </div>
              )}
            </div>
            <div className=" flex-1 flex">
              {startTimer && (
                <Call
                  appId={`${appId}`}
                  channelName={`${consultationId}`}
                  token={agoraInfo?.token || ""}
                  uid={agoraInfo?.uid || 0}
                  timerParams={{ timerEnded }}
                  callType={getCallType(contactMedium)}
                />
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default VideoAudioApp;
