import { useMemo, useState } from "react";
import firebaseApp from "../utils/firebase";
import { ConsultFactory, sortByDate } from "../utils/funcs";
import { MessagePayload /* MessageState */ } from "../utils/firebaseTypes";
import { useConsultationContext } from "../contexts/consultationContext";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  updateDoc,
  doc,
  /* getDoc,
  DocumentReference,
  DocumentData, */
} from "firebase/firestore";
import { useTextCipher } from "./useTextCipher";

const db = getFirestore(firebaseApp);
type ConnectionStateType =
  | "connected"
  | "connecting"
  | "disconnected"
  | "error";

export const useChat = (type: "doctor" | "patient") => {
  const { decrypt } = useTextCipher();
  const consultationDetails = useConsultationContext();
  const [messages, setMessages] = useState<MessagePayload[]>([]);
  const [connectionState, setConnectionState] =
    useState<ConnectionStateType>("disconnected");

  const { doctor, patient } = useMemo(
    () => ConsultFactory(consultationDetails || undefined),
    [consultationDetails]
  );
  const updateMsgStatus = async (roomId: string, msgId: string) => {
    try {
      await updateDoc(doc(db, "Messages", roomId, roomId, msgId), {
        status: true,
      });
    } catch (e) {
      console.error(`Error updating msg-${msgId} status: `, e);
    }
  };

  // const getReply = async (replyRef: DocumentReference<DocumentData> | null) => {
  //   try {
  //     if (replyRef) {
  //       const reply = await getDoc(replyRef);
  //       const data = { ...reply.data(), _id: reply.id };
  //       return data;
  //     }
  //   } catch (error) {
  //     // return null;
  //   }
  // };

  // const transformPayload = (
  //   payload: MessagePayload[]
  // ): Promise<MessageState>[] => {
  //   return payload.map(async (msg) => {
  //     if (msg.replyTo) {
  //       const replyRef = doc(db, msg.replyTo);
  //       const replyObj = ((await getReply(replyRef)) as MessagePayload) || null;
  //       return { ...msg, replyTo: replyObj } as MessageState;
  //     } else {
  //       return { ...msg, replyTo: null } as MessageState;
  //     }
  //   });
  // };

  const connectToRoom = async (roomId: string) => {
    try {
      setConnectionState("connecting");
      const unSubscribe = onSnapshot(
        collection(db, "Messages", roomId, roomId),
        async (querySnapshot) => {
          const msgsArrPayLoadWithId: MessagePayload[] = [];

          querySnapshot.forEach((msgs) => {
            const msgId = msgs.id;
            const msg = { ...msgs.data(), _id: msgId } as MessagePayload;
            const fromDoctor = msg?.idFrom === doctor._id && type === "patient";
            const fromPatient =
              msg?.idFrom === patient._id && type === "doctor";
            fromDoctor && !msg?.status && updateMsgStatus(roomId, msgId);
            fromPatient && !msg?.status && updateMsgStatus(roomId, msgId);

            //decrypt
            msg.content = decrypt(msg.content);
            if (msg.replyTo) {
              const replyToMsg = msg.replyTo.content;
              msg.replyTo.content = decrypt(replyToMsg);
            }
            msgsArrPayLoadWithId.push(msg);
          });

          //const transformedData = transformPayload(msgsArrPayLoadWithId);
          //const messageArr = await Promise.all(transformedData);

          const sortedMsgs = sortByDate(msgsArrPayLoadWithId);
          setMessages(sortedMsgs);
          setConnectionState("connected");
        }
      );

      return unSubscribe;
    } catch (error) {
      console.error("Failed to connect to chat:", error);
      setConnectionState("error");
    }
  };

  const sendMessage = async (
    groupId: string,
    payload: Omit<MessagePayload, "_id">
  ) => {
    try {
      await addDoc(collection(db, "Messages", groupId, groupId), {
        ...payload,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return { connectToRoom, connectionState, messages, sendMessage };
};
