/* eslint-disable indent */
import React, { useState } from "react";
import { Timer } from "./Timer";
import MainModal from "./Modals/Modal";
import useAlert from "../hooks/useAlert";
import { useMutation } from "@apollo/client";
import { WaitingRoomInfoCard } from "./WaitingRoomInfoCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConsultFactory, createConsultationVar } from "../utils/funcs";
import { useConsultationContext } from "../contexts/consultationContext";
import {
  cancelConsultation as cancelConsult,
  createConsultation,
  rebroadcastNotification,
} from "../graphQL/mutations";

const retryCount = parseInt(sessionStorage.getItem("retry_count") || "0");
const limit = 1;

const InstantWaitingRoom = ({
  startTimer,
  cancelConsultation,
}: {
  startTimer: boolean;
  setStartTimer: React.Dispatch<React.SetStateAction<boolean>>;
  cancelConsultation: () => void;
}) => {
  const navigate = useNavigate();
  const { displayAlert } = useAlert();
  const [searchParams] = useSearchParams();
  const retry = searchParams.get("retry");
  const [cancel] = useMutation(cancelConsult);
  const consultationDetails = useConsultationContext();
  const [openRetryModal, setOpenRetryModal] = useState(false);
  const { doctor, isFollowUp } = ConsultFactory(consultationDetails);
  const [rebroadcast, { loading }] = useMutation(rebroadcastNotification);
  const [createNew, { loading: loadingNew }] = useMutation(createConsultation);

  const isLoading = loadingNew || loading;

  const [now] = useState<number>(
    Number(sessionStorage.getItem("waitingRoomStartTime"))
  );

  const rebroadcastConsultation = () => {
    if (!consultationDetails) return;
    if (isFollowUp) sessionStorage.setItem("retry_count", `${retryCount + 1}`);
    const id = consultationDetails?._id;
    rebroadcast({
      variables: {
        consultationId: id,
      },
    })
      .then(() => {
        sessionStorage.removeItem("waitingRoomStartTime");
        sessionStorage.setItem("retry", "true");
        navigate(`/${id}?retry=true`, { replace: true });
        window?.location?.reload();
      })
      .catch((error) => {
        const errMsg = error as Error;
        if (errMsg) displayAlert("error", errMsg?.message);
        console.error("Couldn't recreate consultation", error);
      });
  };

  const createNewFollowUpConsultation = async () => {
    try {
      if (!consultationDetails) return;

      await cancel({
        variables: {
          consultationId: consultationDetails?._id,
          reason: `From waiting room: Follow-up Doctor didn't join after ${limit} retries.`,
        },
      });

      const newConsultationData = createConsultationVar(
        consultationDetails,
        "another_doctor"
      );

      const { data } = await createNew({ variables: newConsultationData });

      if (data) {
        const newConsultationId = data?.createConsultation?.consultation?._id;
        localStorage.clear();
        sessionStorage.clear();
        navigate(`/${newConsultationId}`, { replace: true });
        window?.location?.reload();
      }
    } catch (error) {
      const errMsg = error as Error;
      if (errMsg) displayAlert("error", errMsg?.message);
      console.error(error);
    }
  };

  const doctorName = doctor.firstName;
  const instantText =
    "We're sorry, but all our doctors are currently unavailable. Would you like to retry? If no, this consultation will end.";
  const followUpText = `Wait time exceeded. Please click on the retry button to continue waiting or cancel the consultation.`;
  const followUpExceededText = `It seems Dr. ${doctorName} is not available.`;

  const isFollowUpLimitExceeded = isFollowUp && retryCount >= limit;
  const isFollowUpLimitNotExceeded = isFollowUp && retryCount < limit;

  const firstBtnText = isFollowUpLimitExceeded
    ? "Try another doctor"
    : isFollowUpLimitNotExceeded
    ? "Retry"
    : "Retry";
  const firstBtnFn = isFollowUpLimitExceeded
    ? createNewFollowUpConsultation
    : isFollowUpLimitNotExceeded
    ? rebroadcastConsultation
    : rebroadcastConsultation;

  const secondBtnText = isFollowUpLimitExceeded
    ? "Quit"
    : isFollowUpLimitNotExceeded
    ? "Try another doctor"
    : "Quit";

  const secondBtnFn = isFollowUpLimitExceeded
    ? cancelConsultation
    : isFollowUpLimitNotExceeded
    ? createNewFollowUpConsultation
    : cancelConsultation;

  const showFollowUpText = isFollowUp && retryCount < limit;
  const showFollowUpExceedText = isFollowUpLimitExceeded;
  const text = showFollowUpText
    ? followUpText
    : showFollowUpExceedText
    ? followUpExceededText
    : instantText;

  return (
    <WaitingRoomInfoCard type="user">
      <>
        <Timer
          startTimer={startTimer}
          deadline={now || 180000 + Date.now()}
          onTimerEnd={() => setOpenRetryModal(true)}
          bgColor="bg-[#3E5EA921]"
          color="text-primary"
          showTimeRunningOutWarning={false}
          textBeforeTimer="Wait time"
          threshold={{ mins: 0 }}
          name="waitingRoomStartTime"
          persistType="session"
        />
        {retry && (
          <div className="flex mt-4">
            <button
              onClick={() => cancelConsultation()}
              className="w-full bg-primary hover:bg-blue-800 text-white py-3 px-4"
            >
              Cancel consultation
            </button>
          </div>
        )}
        <MainModal
          open={openRetryModal}
          setOpen={setOpenRetryModal}
          heading=""
          modalLabel="retry-instant-consultation-modal"
          modalDescription=""
          hasCloseBtn={false}
        >
          <div className="flex flex-col justify-center items-center space-y-5">
            <h3 className="text-center text-2xl font-semibold">
              Wait Time Over
            </h3>
            <p className="text-sm text-center">{text}</p>
            <div className=" w-full space-x-2 flex items-center font-semibold">
              <button
                disabled={isLoading}
                className="w-full bg-primary text-sm hover:bg-blue-800 text-white py-3 px-4"
                onClick={() => firstBtnFn()}
              >
                {isLoading ? "Loading..." : firstBtnText}
              </button>
              <button
                disabled={isLoading}
                className="w-full border border-primary hover:border-blue-800 text-sm text-primary hover:text-blue-800 py-3 px-4"
                onClick={() => secondBtnFn()}
              >
                {isLoading ? "Loading..." : secondBtnText}
              </button>
            </div>
          </div>
        </MainModal>
      </>
    </WaitingRoomInfoCard>
  );
};

export default InstantWaitingRoom;
